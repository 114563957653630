import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { useThemeContext } from "modules/themes";

const CustomModalBody = styled(Modal.Body)`
  background-color: ${(props) =>
    props.theme === "dark" ? "#212428 !important;" : "#fff !important;"};
  border-radius: 10px !important;
  padding: 30px;
  z-index: 999;
`;
function CModal(props) {
  const { theme } = useThemeContext();

  return (
    <Modal
      style={{ background: "none" }}
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <CustomModalBody theme={theme} style={props.bodyStyle}>
        {props.children}
      </CustomModalBody>
    </Modal>
  );
}

export default CModal;
