import React, { lazy } from "react";
import { ROUTE_ROLES } from "constants/app-constants";
import { withLayout } from "components/layout/withLayoutHOC";

const Home = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Home })))
);

const ArtistDetail = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.ArtistDetail,
    }))
  )
);

const ArtistCreate = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.ArtistCreate,
    }))
  )
);
const ExploreArtist = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.ExploreArtist,
    }))
  )
);

const EditArtist = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Artist.EditArtist,
    }))
  )
);

const Profile = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Profile.Profile }))
  )
);

const EditProfile = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Profile.EditProfile,
    }))
  )
);

const DetailNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.DetailNft }))
  )
);
const ClaimNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.ClaimNft }))
  )
);

const CreateNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.CreateNft }))
  )
);

const SellNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.SellNft }))
  )
);

const LazyMintNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.LazyMintNft }))
  )
);

const BatchSellNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.BatchSellNft }))
  )
);

const AuctionNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.AuctionNft }))
  )
);

const BatchNftDetail = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.BatchNftDetail }))
  )
);

const AuctionDetail = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.AuctionDetail }))
  )
);

const EditNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.Nft.EditNft }))
  )
);
const ExploreNft = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Nft.ExploreNft,
    }))
  )
);
const OwnedCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.OwnedCollection,
    }))
  )
);

const ExploreCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.ExploreCollection,
    }))
  )
);

const Unauthorized = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Unauthorized.Unauthorized,
    }))
  )
);

// const OwnedGallery = withLayout(
//   lazy(() =>
//     import("./index").then((module) => ({
//       default: module.Gallery.OwnedGallery,
//     }))
//   )
// );

const CreateCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.CreateCollection,
    }))
  )
);

const CreateGallery = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Gallery.CreateGallery,
    }))
  )
);

const EditGallery = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Gallery.EdtGallery,
    }))
  )
);

const ViewGallery = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Gallery.ViewGallery,
    }))
  )
);
const GalleryDetail = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Gallery.GalleryDetail,
    }))
  )
);

const ExploreGallery = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Gallery.ExploreGallery,
    }))
  )
);

const ViewCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.ViewCollection,
      BatchNftDetail,
    }))
  )
);
const EditCollection = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Collection.EditCollection,
    }))
  )
);

// const Explore = withLayout(
//   lazy(() =>
//     import("./index").then((module) => ({
//       default: module.ExploreNFT,
//     }))
//   )
// );

const ConnectWallet = lazy(() =>
  import("./index").then((module) => ({ default: module.ConnectWallet }))
);

const PrivacyPolicy = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.PrivacyPolicy }))
  )
);

const TermsAndConditions = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.TermsAndConditions }))
  )
);

const WhyArtswap = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.WhyArtswap }))
  )
);

const About = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.About })))
);

const Pricing = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Pricing })))
);

const APP_ROUTES = [
  {
    path: "/",
    element: <Home />,
    protected: false,
  },
  {
    path: "/artist/:artist_id",
    element: <ArtistDetail />,
    protected: false,
    checkBlacklist: true,
  },
  {
    path: "/createArtist",
    element: <ArtistCreate />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/exploreArtist",
    element: <ExploreArtist />,
    protected: false,
  },
  {
    path: "/editArtist/:artist_id",
    element: <EditArtist />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/profile",
    element: <Profile />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/edit-profile",
    element: <EditProfile />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/my-gallery",
    element: <ViewGallery />,
    protected: true,
    checkBlacklist: true,
    // accessible_to: [ROUTE_ROLES.GALLERY_OWNER],
  },
  // {
  //   path: "/gallery/:gallery_id",
  //   element: <GalleryDetail />,
  //   protected: false,
  //   // accessible_to: [ROUTE_ROLES.GALLERY_OWNER],
  // },
  {
    path: "/gallery/:vanity_url",
    element: <GalleryDetail />,
    protected: false,
    checkBlacklist: true,

    // accessible_to: [ROUTE_ROLES.GALLERY_OWNER],
  },
  {
    path: "/my-collection",
    element: <OwnedCollection />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/pda-detail/:nft_id",
    element: <DetailNft />,
    protected: false,
    checkBlacklist: true,
  },
  {
    path: "/batch-pda-detail/:nft_id",
    element: <BatchNftDetail />,
    protected: false,
    checkBlacklist: true,
  },
  {
    path: "/auction-pda-detail/:nft_id",
    element: <AuctionDetail />,
    protected: false,
  },
  {
    path: "/auction-pda-detail/:nft_id",
    element: <AuctionDetail />,
    protected: false,
  },
  {
    path: "/claim/:nft_id",
    element: <ClaimNft />,
    // protected: true,
    // checkBlacklist: true
  },
  {
    path: "/sell-pda/:nft_id",
    element: <SellNft />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/lazy-mint-pda/:nft_id",
    element: <LazyMintNft />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/batch-sell-pda/:nft_id",
    element: <BatchSellNft />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/auction-nft/:nft_id",
    element: <AuctionNft />,
    protected: true,
  },
  {
    path: "/createPDA",
    element: <CreateNft />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/editNft/:nft_id",
    element: <EditNft />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/collection/:collection_id",
    element: <ViewCollection />,
    protected: false,
    checkBlacklist: true,
  },
  {
    path: "/edit-collection",
    element: <EditCollection />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/createCollection",
    element: <CreateCollection />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/exploreCollection",
    element: <ExploreCollection />,
    protected: false,
  },
  {
    path: "/createGallery",
    element: <CreateGallery />,
    protected: true,
    checkBlacklist: true,
    accessible_to: [ROUTE_ROLES.GALLERY_OWNER],
  },
  {
    path: "/editGallery",
    element: <EditGallery />,
    protected: true,
    checkBlacklist: true,
  },
  {
    path: "/exploreGallery",
    element: <ExploreGallery />,
    protected: false,
  },
  {
    path: "/exploreNft",
    element: <ExploreNft />,
    protected: false,
  },
  {
    path: "/wallet",
    element: <ConnectWallet />,
    protected: false,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
    protected: false,
  },
  // {
  //   path: "/explore",
  //   element: <Explore />,
  //   protected: false,
  // },
  {
    path: "/terms",
    element: <TermsAndConditions />,
    protected: false,
  },
  {
    path: "/why-artswap",
    element: <WhyArtswap />,
    protected: false,
  },
  {
    path: "/about",
    element: <About />,
    protected: false,
  },
  {
    path: "/pricing",
    element: <Pricing />,
    protected: false,
  },
  {
    path: "/unauthorized",
    element: <Unauthorized />,
    protected: false,
  },
];

export default APP_ROUTES;
