import React, { createContext, useContext } from "react";
import { toast } from "react-toastify";
import { useThemeContext } from "modules/themes";

const ToastContext = createContext(null);

export const ToastContextProvider = ({ children }) => {
  const { theme } = useThemeContext();

  const showSuccess = (msg) =>
    toast.success(msg || "Operation Successful", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme,
    });

  const showError = (msg) =>
    toast.error(msg || "Operation Failed", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme,
    });

  return (
    <ToastContext.Provider
      value={{
        showError,
        showSuccess,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
