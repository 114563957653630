import { withLayout } from "components/layout/withLayoutHOC";
import { ROUTE_ROLES } from "constants/app-constants";
import React, { lazy } from "react";

const Login = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Login })))
);
const SignUp = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Signup })))
);
const Verify = withLayout(
  lazy(() => import("./index").then((module) => ({ default: module.Verify })))
);
const WalletRegister = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Wallet.WalletRegister,
    }))
  )
);
const BecomeGalleryOwner = withLayout(
  lazy(() =>
    import("./index").then((module) => ({ default: module.BeGalleryOwner }))
  )
);
const AddUserWallet = withLayout(
  lazy(() =>
    import("./index").then((module) => ({
      default: module.Wallet.AddUserWallet,
    }))
  )
);

const AUTH_ROUTES = [
  {
    path: "/login",
    element: <Login />,
    protected: false,
  },
  {
    path: "/become_gallery_owner",
    element: <BecomeGalleryOwner />,
    protected: true,
    checkBlacklist: true,
    accessible_to: [
      ROUTE_ROLES.ARTIST,
      ROUTE_ROLES.COLLECTOR_ROLE,
      ROUTE_ROLES.GALLERY_OWNER,
    ],
  },
  {
    path: "/signup",
    element: <SignUp />,
    protected: false,
  },
  {
    path: "/verifyOtp",
    element: <Verify />,
    protected: false,
  },
  {
    path: "/walletRegister",
    element: <WalletRegister />,
    protected: false,
  },
  {
    path: "/addUserWallet",
    element: <AddUserWallet />,
    protected: true,
    checkBlacklist: true
  },
];

export default AUTH_ROUTES;
