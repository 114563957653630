import axios from "../../utils/requestInstance";
import { API } from "../../constants";

const {
  SETTINGS: { GET_SETTINGS_BY_CHAINID, LIST_SETTINGS, GET_SETTINGS_BY_ID,GET_SETTINGS_BY_CHAINID_VERSION },
} = API;

export const listSettings = async () => {
  const response = await axios.get(LIST_SETTINGS);
  return response.data;
};

export const getSettingsByChainId = async (chainId) => {
  const response = await axios.get(`${GET_SETTINGS_BY_CHAINID}/${chainId}`);
  return response.data;
};

export const getSettingsById = async (id) => {
  const response = await axios.get(`${GET_SETTINGS_BY_ID}/${id}`);
  return response.data;
};
export const getSettingByIdandVersion = async(id,version)=>{
  const response = await axios.get(`${GET_SETTINGS_BY_CHAINID_VERSION}/${id}/${version}`);
  return response.data;
}
