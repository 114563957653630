import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import styled from "styled-components";

const Canvas = styled(Offcanvas)`
  background: ${(props) => props?.background || "white"};
  z-index: 2000;
`;

function OffCanvasComponent(props) {
  const {
    show,
    handleClose,
    placement,
    header = false,
    title,
    ...rest
  } = props;
  return (
    <Canvas
      {...rest}
      show={show}
      onHide={handleClose}
      placement={placement || "start"}>
      {header && (
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title || ""}</Offcanvas.Title>
        </Offcanvas.Header>
      )}
      <Offcanvas.Body>{props.children}</Offcanvas.Body>
    </Canvas>
  );
}

export default OffCanvasComponent;
