import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "modules/toast/context";
import * as SessionManager from "utils/sessionManager";
import axios from "utils/requestInstance";

const currentUser = SessionManager.getCurrentUser();

function CheckBlacklistUser({ children }) {
  const navigate = useNavigate();
  const { showError } = useToastContext();

  const checkBlackList = async () => {
    if (currentUser) {
      const user = await axios.get(`/users/user-status/${currentUser.id}`);
      const status = user?.data;
      if (status) {
        showError("Your account has been blocked. Please contact admin.");
        navigate("/unauthorized");
      }
    }
  };
  useEffect(() => {
    checkBlackList();
  });

  return children;
}

export default CheckBlacklistUser;
