import React, { useState, useCallback } from "react";
import Web3 from "web3";
import { useWeb3React } from "@web3-react/core";

import { useToastContext } from "modules/toast/context";
import { useWrappedTokenSignerContract } from "hooks/useSignerContract";

import CustomModal from "components/components/Modal";
import Loading from "components/components/Loading";
import { COMMON_BASE_TOKENS } from "constants/chain.constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useThemeChange } from "utils/themeChange";

const WrappedModal = ({ exchangeModel, toggleExchangeModel }) => {
  const wrappedTokenContract = useWrappedTokenSignerContract();
  const { showError, showSuccess } = useToastContext();
  const themeChange = useThemeChange();

  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [toWrapStatus, setToWrapStatus] = useState(true);
  const { chainId, account } = useWeb3React();

  const exchangeToken = useCallback(async () => {
    if (!account) return;
    setLoading(true);
    try {
      const amountToExchange = Web3.utils.toWei(amount.toString());
      if (toWrapStatus) {
        await wrappedTokenContract.methods
          .deposit()
          .send({ from: account, value: amountToExchange });
      } else {
        await wrappedTokenContract.methods
          .withdraw(amountToExchange)
          .send({ from: account });
      }
      showSuccess("Token exchanged successfully");
      setLoading(false);
      toggleExchangeModel();
      return;
    } catch (err) {
      setLoading(false);
      return showError(err?.message || "Something went wrong");
    }
  }, [account, amount, wrappedTokenContract]);

  return (
    <CustomModal
      bodyStyle={
        window.innerWidth > 400
          ? {
              margin: "auto",
              width: "100%",
            }
          : {}
      }
      show={exchangeModel}
      onHide={toggleExchangeModel}
    >
      <div className={themeChange.add("section-2")}>
        <div className="checkout">
          <div className="maincheckout">
            <div className="row">
              <div className="col">
                <h3>Exchange Token</h3>
              </div>
              <div className="col-1">
                <button
                  className="btn-close btn-close-white"
                  onClick={toggleExchangeModel}
                />
              </div>
            </div>
            {/* <div className="weth_modal_top w-100 mb-3">
              Swap for {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol}
            </div> */}
            <div>
              <div className="swap_weth">
                <p>
                  Easily convert between {COMMON_BASE_TOKENS[chainId]?.symbol}{" "}
                  and {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol}
                  <OverlayTrigger
                    key="wrappedToken"
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-wrappedToken`}>
                        Wrapped {COMMON_BASE_TOKENS[chainId]?.symbol} (
                        {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol}) is used to
                        make a bid on an auctioned PDA. ArtSwap doesnot mint{" "}
                        {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol} or exchange{" "}
                        {COMMON_BASE_TOKENS[chainId]?.symbol} for{" "}
                        {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol}, and
                        ArtSwap does not operate or control the{" "}
                        {COMMON_BASE_TOKENS[chainId]?.wrappedSymbol} smart
                        contract.
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle"
                      aria-hidden="true"
                      style={{ marginLeft: 2 }}
                    ></i>
                  </OverlayTrigger>
                </p>
              </div>
              <div className="swap_bottom">
                <strong className="px-2">Swap</strong>
                <div className="d-flex mb-3">
                  <input
                    className="w-100 px-2"
                    type="text"
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="0"
                  />
                  <img
                    className="mt-2"
                    src={
                      toWrapStatus
                        ? COMMON_BASE_TOKENS[chainId]?.imgSrc
                        : COMMON_BASE_TOKENS[chainId]?.wrappedImgSrc
                    }
                    width={20}
                    height={20}
                  />
                  <span>
                    {toWrapStatus
                      ? COMMON_BASE_TOKENS[chainId]?.symbol
                      : COMMON_BASE_TOKENS[chainId]?.wrappedSymbol}
                  </span>
                </div>
                <span
                  className="exchangeIcon"
                  onClick={() => setToWrapStatus(!toWrapStatus)}
                >
                  {/* <i
                    className="fa fa-exchange fa-rotate-90"
                    aria-hidden="true"
                  ></i> */}
                  <svg
                    width="14"
                    height="20"
                    viewBox="0 0 14 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke-width="1.5"
                    class="icons-sc-lekdau-0 ikXrwp"
                  >
                    <path
                      d="M5.33317 5.41663L9.08317 1.66663M9.08317 1.66663L12.8332 5.41663M9.08317 1.66663V9.99996M8.6665 14.5833L4.9165 18.3333M4.9165 18.3333L1.1665 14.5833M4.9165 18.3333L4.9165 10.8333"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
                <div className="swap_bottom_child">
                  <strong className="px-1">For</strong>
                  <div className="d-flex mb-2">
                    <input
                      className="w-100 px-1"
                      type="text"
                      value={amount}
                      placeholder="0"
                      disabled
                    />
                    <img
                      className="mt-2"
                      src={
                        toWrapStatus
                          ? COMMON_BASE_TOKENS[chainId]?.wrappedImgSrc
                          : COMMON_BASE_TOKENS[chainId]?.imgSrc
                      }
                      width={20}
                      height={20}
                    />
                    <span>
                      {toWrapStatus
                        ? COMMON_BASE_TOKENS[chainId]?.wrappedSymbol
                        : COMMON_BASE_TOKENS[chainId]?.symbol}
                    </span>
                  </div>
                </div>
              </div>
              {loading ? (
                <Loading
                  loadingMessage="Exchanging token"
                  style={{ width: "100%", marginTop: "9px" }}
                />
              ) : (
                <button
                  className="btn-main w-100 mt-3"
                  disabled={+amount === 0}
                  onClick={exchangeToken}
                >
                  Exchange
                </button>
              )}
              <a
                href="https://buy.moonpay.com/?defaultCurrencyCode=ETH"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button className="btn-main-secondary w-100 mt-2">
                  Buy with MoonPay
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default WrappedModal;
