import React, { useEffect, useState, useMemo, useCallback } from "react";
import Breakpoint, {
  BreakpointProvider,
  setDefaultBreakpoints,
} from "react-socks";
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import * as SessionManager from "utils/sessionManager";
import { useWeb3React } from "@web3-react/core";
import WalletCanvas from "./WalletCanvas";
import { IPFS_URL } from "constants/api-constants";
import { ROUTE_ROLES } from "constants/app-constants";
import { SUPPORTED_CHAIN_ID } from "constants/global-constants";
import { useThemeContext } from "modules/themes";
import { useThemeChange } from "utils/themeChange";

// import SelectWrapper from "components/components/SelectWrapper";

import ErrorMessage from "components/components/networkErrorMessage/index";
import { WalletIcon } from "components/components/Icons";

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);

const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? "active" : "non-active",
      };
    }}
  />
);

const Header = function () {
  const { account, chainId } = useWeb3React();
  const { theme, handleThemeChange } = useThemeContext();
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState();
  const [userRoles, setUserRoles] = useState([]);
  const [showpop, btn_icon_pop] = useState(false);
  const [showmenu, btn_icon] = useState(false);
  const themeChange = useThemeChange();

  const closePop = () => {
    btn_icon_pop(false);
  };

  const refpop = useOnclickOutside(() => {
    closePop();
  });

  const logout = () => {
    const myTheme = localStorage.getItem("theme") || "dark";
    localStorage.clear();
    localStorage.setItem("theme", myTheme);
    if (window.location.pathname === "/unauthorized") {
      window.location.href = "/";
    } else {
      window.location.reload();
    }
  };

  const walletAddress = useMemo(
    () =>
      account &&
      `${account?.slice(0, 7)}...${account?.slice(
        account?.length - 4,
        account?.length
      )}`,
    [account]
  );

  useEffect(() => {
    const header = document.getElementById("myHeader");
    const totop = document.getElementById("scroll-to-top");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      btn_icon(false);
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        totop.classList.add("show");
      } else {
        header.classList.remove("sticky");
        totop.classList.remove("show");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  const [showCanvas, setCanvas] = useState(false);

  const toggleCanvas = useCallback(() => {
    setCanvas((prev) => !prev);
  }, []);

  const fetchUserImage = useCallback(async () => {
    const savedUser = SessionManager.getCurrentUser();
    const roles = SessionManager.getRoles();
    setUserRoles(roles);
    setUser(savedUser);
    if (!savedUser) return;
    try {
      setUser(savedUser);
    } catch (err) {}
  }, []);

  useEffect(() => {
    const token = SessionManager.getAccessToken();
    setLoggedIn(!!token);
    fetchUserImage();
  }, [fetchUserImage]);

  return (
    <div className={themeChange.add("section-2")}>
      <header id="myHeader" className="navbar white">
        <WalletCanvas show={showCanvas} handleClose={() => setCanvas(false)} />

        <div className="container">
          <div className="row w-100-nav">
            <div className="logo px-0">
              <div
                className="navbar-title navbar-item"
                style={{ width: "145px" }}>
                <NavLink to="/">
                  <img
                    src={"/img/art-lowres.png"}
                    className="img-fluid d-block"
                    alt="#"
                    width={145}
                  />
                  <img
                    src={
                      theme === "dark"
                        ? "/img/art-lowres-dark.png"
                        : "/img/art-lowres-light.png"
                    }
                    className="img-fluid d-3"
                    alt="#"
                    width={145}
                  />
                  <img
                    src={"/img/art-lowres.png"}
                    className="img-fluid d-none"
                    alt="#"
                    width={145}
                  />
                </NavLink>
              </div>
            </div>
            <div className="search"></div>
            <BreakpointProvider>
              <Breakpoint l down>
                {showmenu && (
                  <div className="menu">
                    <div className="navbar-item">
                      <NavLink to="/" onClick={() => btn_icon(!showmenu)}>
                        Home
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <NavLink
                        to="/why-artswap"
                        onClick={() => btn_icon(!showmenu)}>
                        Why Artswap
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <NavLink to="/about" onClick={() => btn_icon(!showmenu)}>
                        About
                      </NavLink>
                    </div>

                    <div className="navbar-item">
                      <NavLink
                        to="/pricing"
                        onClick={() => btn_icon(!showmenu)}>
                        Pricing
                      </NavLink>
                    </div>
                    <div className="navbar-item">
                      <div className="toggle">
                        <span
                          style={
                            theme === "dark"
                              ? { color: "#fff" }
                              : { color: "#04111C" }
                          }>
                          Light Mode
                        </span>
                        <label>
                          <input
                            type="checkbox"
                            defaultChecked={theme === "dark" ? false : true}
                            onClick={handleThemeChange}
                          />
                          <span />
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </Breakpoint>

              <Breakpoint xl>
                <div className="menu">
                  <div className="navbar-item">
                    <NavLink to="/">Home</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/why-artswap">Why Artswap</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/about">About</NavLink>
                  </div>
                  <div className="navbar-item">
                    <NavLink to="/pricing">Pricing</NavLink>
                  </div>
                  {/* <div className="navbar-item">
                    <div
                      className="light-dark-btn d-none d-sm-block"
                      onClick={handleThemeChange}
                    >
                      { {theme} }
                      <i
                        className={
                          theme === "dark" ? "fa fa-moon-o" : "fa fa-sun-o"
                        }
                        style={{ color: "white" }}
                      ></i>
                    </div>
                  </div> */}
                </div>
              </Breakpoint>
            </BreakpointProvider>
            <div className="mainside d-flex justify-content-between align-items-center">
              <div
                className="light-dark-btn d-none d-xl-block"
                onClick={handleThemeChange}>
                <i
                  className={theme === "dark" ? "fa fa-moon-o" : "fa fa-sun-o"}
                  style={{ color: "white" }}></i>
              </div>
            </div>

            <div className="mainside d-flex justify-content-between align-items-center">
              <div
                className="btn-main d-none d-sm-block"
                onClick={toggleCanvas}>
                {!account ? "Connect Wallet" : walletAddress}
              </div>
              <div className="d-block d-sm-none" onClick={toggleCanvas}>
                <button
                  type="button"
                  className="btn btn-sm"
                  style={{
                    padding: "4px 25px",
                    background: theme === "dark" ? "#ff343f" : "#8364e2",
                    borderRadius: "8px",
                  }}>
                  <WalletIcon />
                </button>
              </div>

              {isLoggedIn && (
                <div
                  id="de-click-menu-profile"
                  className="de-menu-profile"
                  onClick={() => btn_icon_pop(!showpop)}
                  ref={refpop}
                  style={{ marginLeft: "10px" }}>
                  {user?.profileImage && (
                    <img src={`${IPFS_URL}/${user.profileImage}`} alt="" />
                  )}

                  {!user?.profileImage && (
                    <div className="dummy-avatar-text">
                      <p>{user?.name?.first[0].toUpperCase()}</p>
                    </div>
                  )}

                  {showpop && (
                    <div className="popshow">
                      <div className="d-name">
                        <h4>
                          {user && user.name
                            ? user.name.first.concat(" ", user.name.last)
                            : "-"}
                        </h4>
                      </div>
                      <ul className="de-submenu-profile">
                        <li>
                          <NavLink
                            to="/profile"
                            style={{
                              background: "none",
                              boxShadow: "none",
                              padding: "0px",
                              margin: "0px",
                            }}>
                            <span>
                              <i className="fa fa-user"></i> Profile
                            </span>
                          </NavLink>
                        </li>
                        {userRoles &&
                        userRoles?.length === 1 &&
                        userRoles.includes(ROUTE_ROLES.GALLERY_OWNER) ? (
                          <li>
                            <NavLink
                              to="/my-gallery"
                              style={{
                                background: "none",
                                boxShadow: "none",
                                padding: "0px",
                                margin: "0px",
                              }}>
                              <span>
                                <i
                                  className="fa fa-picture-o fa-sm"
                                  style={{ paddingLeft: "7px" }}></i>
                                Gallery
                              </span>
                            </NavLink>
                          </li>
                        ) : null}
                        {userRoles &&
                        userRoles?.length === 2 &&
                        userRoles.includes(ROUTE_ROLES.GALLERY_OWNER) &&
                        userRoles.includes(ROUTE_ROLES.COLLECTOR_ROLE) &&
                        user?.email_verified ? (
                          <li>
                            <NavLink
                              to="/my-gallery"
                              style={{
                                background: "none",
                                boxShadow: "none",
                                padding: "0px",
                                margin: "0px",
                              }}>
                              <span>
                                <i
                                  className="fa fa-picture-o fa-sm"
                                  style={{ paddingLeft: "7px" }}></i>
                                Gallery
                              </span>
                            </NavLink>
                          </li>
                        ) : null}
                        <li>
                          <NavLink
                            to="/edit-profile"
                            style={{
                              background: "none",
                              boxShadow: "none",
                              padding: "0px",
                              margin: "0px",
                            }}>
                            <span>
                              <i
                                className="fa fa-pencil fa-sm"
                                style={{ paddingLeft: "7px" }}></i>{" "}
                              Edit Profile
                            </span>
                          </NavLink>
                        </li>
                        <li>
                          <span onClick={logout} className="signout-menu-item">
                            <i className="fa fa-sign-out"></i> Sign Out
                          </span>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <button className="nav-icon" onClick={() => btn_icon(!showmenu)}>
            <div className="menu-line white"></div>
            <div className="menu-line1 white"></div>
            <div className="menu-line2 white"></div>
          </button>
        </div>
        {chainId && !SUPPORTED_CHAIN_ID.includes(chainId) ? (
          <ErrorMessage />
        ) : (
          ""
        )}
      </header>
    </div>
  );
};
export default Header;
