export const POLYGON_TEST = "polygon-test";
export const POLYGON_MAIN = "polygon-main";
export const DEFAULT_NET = "polygon-main";
export const BINANCE_TEST = "binance-test";
export const BINANCE_MAIN = "binance-main";
export const ETHEREUM_MAIN = "ethereum-main";
export const ETHEREUM_TEST = "etherum-test";

export const POLYGON_EXPLORER = process.env.REACT_APP_POLYGON_EXPLORER;

export const POLYGON_TEST_NET_CHAIN_ID = 80001;
export const POLYGON_MAIN_NET_CHAIN_ID = 137;
export const BINANCE_TEST_NET_CHAIN_ID = 97;
export const BINANCE_MAIN_NET_CHAIN_ID = 56;
export const ETHEREUM_MAIN_NET_CHAIN_ID = 1;
export const ETHEREUM_TEST_NET_CHAIN_ID = 11155111;
export const DEFAULT_CHAIN_ID =
  process.env.REACT_APP_CHAIN_ID || POLYGON_MAIN_NET_CHAIN_ID;

export const SUPPORTED_CHAIN_ID = [
  POLYGON_TEST_NET_CHAIN_ID,
  POLYGON_MAIN_NET_CHAIN_ID,
  BINANCE_TEST_NET_CHAIN_ID,
  BINANCE_MAIN_NET_CHAIN_ID,
  ETHEREUM_MAIN_NET_CHAIN_ID,
  ETHEREUM_TEST_NET_CHAIN_ID,
];
export const GASFEE_STATUS =
  process.env.REACT_APP_GAS_FEE_STATUS || "FastGasPrice";
export const EXPLORER_KEY = {
  [POLYGON_TEST_NET_CHAIN_ID]: process.env.REACT_APP_POLYGON_EXPLORER_KEY,
  [POLYGON_MAIN_NET_CHAIN_ID]: process.env.REACT_APP_POLYGON_EXPLORER_KEY,
  [BINANCE_MAIN_NET_CHAIN_ID]: process.env.REACT_APP_BINANCE_EXPLORER_KEY,
  [BINANCE_TEST_NET_CHAIN_ID]: process.env.REACT_APP_BINANCE_EXPLORER_KEY,
  [ETHEREUM_TEST_NET_CHAIN_ID]: process.env.REACT_APP_ETHEREUM_EXPLORER_KEY,
  [ETHEREUM_MAIN_NET_CHAIN_ID]: process.env.REACT_APP_ETHEREUM_EXPLORER_KEY,
};

const DECIMAL_VALUE = process.env.REACT_APP_DECIMAL_PLACE_LIMIT;

export const NFT_STATUS = {
  listed: { label: "On Sale", value: "listed" },
  unlisted: { label: "Not For Sale", value: "unlisted" },
  sold: { label: "Sold", value: "sold" },
  pending: { label: "Pending", value: "pending" },
  lazyminted: { label: "Lazy Minted", value: "lazyminted" },
  batchminted: { label: "Batch Minted", value: "batchminted" },
  auction: { label: "Auction", value: "auction" },
};

export const OWNED_COLLECTION = {
  list: {
    label: "Collections",
    value: "collections",
  },
};

export const FIAT_CURRENCIES = {
  USD: "USD",
};
export const DECIMAL = DECIMAL_VALUE;
export const PRICE_CONSTANTS = 1000;

export const RANDOM_NUMBER = {
  MAX: 99999,
  MIN: 1000,
};

export const REFRESH_TIME = 30000;
export const EMAIL_VERIFICATION_KEY_LENGTH = 10;

const EVENT_TYPES = {
  TRANSFER_NFT: "TRANSFER_NFT",
};

export const EVENTS = {
  [EVENT_TYPES.TRANSFER_NFT]: "Transfer(address,address,tokenId)",
};

export const NFTEVENTS = {
  BUY_NFT: "buy_nft",
  SELL_NFT: "sell_nft",
  CANCEL_SELL: "cancel_sell",
  AIRDROP_NFT: "airdrop_nft",
  MINT_SELL_NFT: "mint_sell_nft",
  CREATE_SELL_NFT: "create_sell_nft",
  TRANSFER_NFT: "transfer_nft",
  BURN_NFT: "burn_nft",
  CLAIM_NFT: "claim_nft",
  MINT_AIRDROP_EMAIL: "mint_airdrop_email",
  MINT_AIRDROP: "mint_airdrop",
  BUY_LAZYMINTED: "buy_lazyminted",
  BATCH_MINT_NFT: "batch_mint_nft",
  BUY_BATCH_MINTED_NFT: "buy_batch_minted_nft",
  CANCEL_BATCH_MINTED: "cancel_batch_minted",
  BATCH_SELL_NFT: "batch_sell_nft",
  MINT_AUCTION_NFT: "mint_auction_nft",
  AUCTION_NFT: "auction_nft",
  SECONDARY_AUCTION_NFT: "secondary_auction_nft",
  CANCEL_AUCTION: "cancel_auction",
  BID_AUCTION: "bid_auction",
  CLAIM_AUCTION: "claim auction",
};

export const MOONPAY_URL = "https://buy.moonpay.com/?defaultCurrencyCode=ETH";

export const SIGNING_MESSAGE = `Welcome to ArtSwap
Click to sign and accept ArtSwap Terms and Conditions: https://app.artswap.com/terms
Message Hash
`;
