import React from "react";
import Header from "./header";
import {SearchContextProvider} from "../../modules/search/context"

function Index (){
    return(
        <SearchContextProvider>
            <Header/>
        </SearchContextProvider>
    )
}
export default Index;