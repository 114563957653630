import { useMemo } from "react";
import galleryFactoryAbi from "config/abi/GalleryFactory.json";
import nftAbi from "config/abi/NFT.json";
import { useWeb3React } from "@web3-react/core";
import { useSettingsContext } from "modules/settings/context";

export const useLibrary = (networkId) => {
  const { library, chainId } = useWeb3React();
  return useMemo(() => {
    if (!chainId || !library) return;
    if (chainId && networkId?.toString() === chainId?.toString())
      return library;
  }, [chainId, library, networkId]);
};

export const useSignerContract = (
  abi,
  address,
  networkId,
  contractOptions = {}
) => {
  const library = useLibrary(networkId);
  return useMemo(() => {
    if (!address) return null;
    if (!abi) return null;
    if (!library) return null;
    return new library.eth.Contract(abi, address, contractOptions);
  }, [library, abi, address, contractOptions]);
};

// todo: find a better architecture
export const useGalleryFactorySignerContract = () => {
  const settingsContext = useSettingsContext();
  // might not always return a chainId, as a default one has not been set inside these hooks
  const { chainId } = useWeb3React();

  const abi = galleryFactoryAbi.abi;
  const contractAddress = settingsContext.galleryFactory[chainId];
  return useSignerContract(abi, contractAddress, chainId);
};

export const useNftSignerContract = () => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();

  const abi = nftAbi.abi;
  return useSignerContract(abi, settingsContext.nft[chainId], chainId);
};

export const useMarketPlaceSignerContract = () => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();
  const abi = settingsContext.abi.marketPlace;
  return useSignerContract(abi, settingsContext?.marketPlace[chainId], chainId);
};

export const useGallerySignerContract = (address, networkId) => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();
  const abi = settingsContext.abi.gallery;
  const contract = useSignerContract(abi, address || "", chainId);
  return contract;
};

export const useLazyMinterSignerContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();
  const address = settingsContext?.lazyMinting[chainId];
  const abi = settingsContext.abi.lazyMinting;
  const contract = useSignerContract(abi, address || "", chainId);
  return contract;
};

export const useAuctionSignerContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();
  const address = settingsContext?.auction[chainId];
  const abi = settingsContext.abi.auction;
  return useSignerContract(abi, address || "", chainId);
};

export const useWrappedTokenSignerContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const { chainId } = useWeb3React();
  const address = settingsContext.wrappedToken[chainId];
  const abi = settingsContext.abi.wrappedToken;
  return useSignerContract(abi, address || "", chainId);
};
