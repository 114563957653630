import walletIcon from "../../../assets/img/wallet/wallet.png";
import walletLightIcon from "../../../assets/img/wallet/wallet2.png";
import { useThemeContext } from "modules/themes";

export const PolygonMatic = ({ style, src }) => (
  <img style={{ width: 20, height: 20, ...style }} src={src} alt="MaticIcon" />
);
export const WalletIcon = ({ style }) => {
  const { theme } = useThemeContext();
  return (
    <img
      style={{ width: 25, height: 25, ...style }}
      src={ theme === "dark" ? walletIcon : walletLightIcon}
      alt="MaticIcon"
    />
  );
}
