import React, { useCallback, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import CheckBlacklistUser from "utils/checkBlacklistUser";
import * as SessionManager from "utils/sessionManager";
const access_token = SessionManager.getAccessToken();
const roles = SessionManager.getRoles();

export default function useRouteProtect(routeConfig) {
  const [routes, setRoutes] = useState(null);

  const filterRoutes = useCallback(() => {
    const filtered = routeConfig.map((route) => {
      if (!route?.protected && !route?.checkBlacklist) return route;

      if (route?.protected && !access_token)
        return {
          ...route,
          element: <Navigate to="/login" replace={true} />,
        };
      if (route?.accessible_to && route.accessible_to?.length) {
        if (!roles || !roles?.length) {
          return {
            ...route,
            element: <Navigate to="/login" replace={true} />,
          };
        }
        const hasPermission = roles.some((item) =>
          route.accessible_to.includes(item)
        );
        if (!hasPermission) {
          return {
            ...route,
            element: <Navigate to="/login" replace={true} />,
          };
        }
      }

      if (access_token && route?.checkBlacklist) {
        return {
          ...route,
          element: <CheckBlacklistUser>{route.element}</CheckBlacklistUser>,
        };
      }

      return route;
    });

    setRoutes(filtered);
  }, [routeConfig]);

  useEffect(() => filterRoutes(), [filterRoutes]);

  return routes;
}
