import React, { useEffect, useCallback } from "react";
import { useToastContext } from "modules/toast/context";
import * as SessionManager from "utils/sessionManager";
import { io } from "socket.io-client";
import { SERVER_URL } from "constants/api-constants";

import * as Service from "modules/nft/service";

const Notification = () => {
  const currentUser = SessionManager.getCurrentUser();

  const { showError, showSuccess } = useToastContext();

  const loadFromSockets = useCallback(() => {
    try {
      const socket = io(`${SERVER_URL}`);
      if (currentUser) {
        socket.emit("clientId", { id: currentUser._id });
      }
      socket.on("new-notification", (data) => {
        if (data.notification.userId === currentUser._id) {
          showSuccess(data.message);
          Service.updateNotification(data.notification._id);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentUser, showSuccess]);
  useEffect(() => {
    loadFromSockets();
  }, [loadFromSockets]);
  return <></>;
};
export default Notification;
