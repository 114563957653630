import React, { useState } from "react";
import { copyAddress } from "utils/copyAddress";

const ClickToCopy = ({ children, text, ...props }) => {
  const [actionCopy, setActionCopy] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleCopy = () => {
    setActionCopy(true);
    copyAddress();
    setTimeout(() => {
      setActionCopy(false);
    }, 800);
  };

  const onMouseEnter = () => {
    setHovered(true);
  };
  const onMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={handleCopy}
      {...props}>
      <>
        {children}

        {hovered && !actionCopy && <p className='text-copied'>Click to Copy</p>}
      </>
      {actionCopy && <p className='text-copied'>Copied</p>}
      <span id='wallet' style={{ display: "none" }}>
        {text}
      </span>
    </div>
  );
};

export default ClickToCopy;
