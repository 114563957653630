import React, { Suspense, useCallback, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTopBtn from "./components/menu/ScrollToTop";
import RouteHandler from "router";
import { WalletContextProvider } from "modules/wallet/context";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { ToastContextProvider } from "modules/toast/context";
import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import * as SessionManager from "utils/sessionManager";
import Notification from "views/app-views/Notification/notification";
import Loader from "components/components/Spinner";
import { useSettingsContext } from "modules/settings/context";
import { ThemeContextProvider } from "modules/themes";
import Loading from "components/components/PageLoading/Loading";

export const ScrollTop = ({ children, location }) => {
  React.useEffect(() => window.scrollTo(0, 0), [location]);
  return children;
};


const App = () => {
  const { handleListAndSetContractSettings } = useSettingsContext();

  const currentUser = SessionManager.getCurrentUser();

  const handleListAppSetting = useCallback(async () => {
    await handleListAndSetContractSettings();
  }, [handleListAndSetContractSettings]);
  useEffect(() => {
    handleListAppSetting();
  }, []);

  return (
    <ThemeContextProvider>
      <Suspense fallback={<Loading />}>
        <ErrorBoundary>
          <Router>
            <ToastContextProvider>
              <WalletContextProvider>
                <ScrollTop path="/">
                  <Routes>
                    <Route path="*" element={<RouteHandler />}></Route>
                  </Routes>
                  <Notification />
                </ScrollTop>
              </WalletContextProvider>
            </ToastContextProvider>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="dark"
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Router>
        </ErrorBoundary>
        <ScrollToTopBtn />

      </Suspense>
    </ThemeContextProvider>
  );
};
export default App;
