const COLLECTOR_ROLE = "Collector";
const GALLERY_OWNER = "Gallery";
const ALL = "All";
const ARTIST = "Artist";
const VERIFY_STAGE = "verifyOtp";
const USER_SIGN_UP_STAGE = "userSignUp";
const GALLERY_REGISTER = "galleryRegister";
const HOME_PAGE = "Home Page";
const PRIVACY = "Privacy";
const TERMS_AND_CONDITION = "Terms and Conditions";
const WHY_ARTSWAP = "Why Artswap";
const ABOUT_US = "About Us";

export const GALLERY_SETUP_STAGES = {
  VERIFY_STAGE,
  USER_SIGN_UP_STAGE,
  GALLERY_REGISTER,
};

export const ROUTE_ROLES = {
  COLLECTOR_ROLE,
  GALLERY_OWNER,
  ARTIST,
  ALL,
};

export const CMS_TEMPLATE = {
  HOME_PAGE,
  PRIVACY,
  TERMS_AND_CONDITION,
  WHY_ARTSWAP,
  ABOUT_US,
};
