import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "views";
import useRouteProtect from "hooks/useRouteProtect";

const UNKNOWN = lazy(() => import("views/unknown-views"));

const RouteHandler = () => {
  const routes = useRouteProtect(ROUTES);
  return (
    <Routes>
      {routes?.length > 0 &&
        routes.map((routeConfig, index) => {
          return <Route {...routeConfig} key={index} />;
        })}
      <Route path="*" element={<UNKNOWN />} />
    </Routes>
  );
};

export default RouteHandler;
