import React, { createContext, useContext, useCallback } from "react";
import * as Service from "./service";
import * as SessionManager from "utils/sessionManager";

const AuthContext = createContext(null);

export const AuthContextProvider = ({ children }) => {
  const onSignUpSuccess = useCallback((res) => {
    SessionManager.saveOtpDetails(res?.otpdetails || {});
  }, []);

  const onSaveEmail = useCallback((res)=>{
    SessionManager.saveEmail(res?.email)
  },[])

  const handleWalletLogin = useCallback(async (payload) => {
    const response = await Service.walletLogin(payload);
    return response;
  }, []);

  const handleSignUp = useCallback(
    async (payload = {}) => {
      onSaveEmail(payload)
      const response = await Service.signUp(payload);
      return onSignUpSuccess(response);
    },
    [onSignUpSuccess,onSaveEmail]
  );

  const handleLoginIn = useCallback(
    async (payload = {}) => {
      onSaveEmail(payload)
      const response = await Service.login(payload);
      return onSignUpSuccess(response);
    },
    [onSignUpSuccess,onSaveEmail]
  );

  const onOtpVerified = useCallback((data) => {
    const { token, user } = data;
    SessionManager.saveCurrentUser(user || "");
    const { roles = [] } = user;
    SessionManager.saveRoles(roles);
    SessionManager.saveAccessToken(token || "");
    SessionManager.removeOtpDetails();
    if(SessionManager.getEmail) SessionManager.removeEmail();
  }, []);

  const onUpdateSuccess = useCallback((data) => {
    const { user } = data;
    SessionManager.saveCurrentUser(user || "");
  }, []);

  const handleVerifyOtp = useCallback(
    async (otp, { onSuccess = () => {}, onError = () => {} } = {}) => {
      try {
        const otpDetails = SessionManager.getOtpDetails();
        if (!otpDetails) throw new Error("Something went wrong");
        const resposne = await Service.verifyOtp({
          otp,
          otpDetails: {
            id: otpDetails.id,
          },
        });
        onSuccess(resposne);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleVerifyOtpForAirdrop = useCallback(
    async (payload) => {
      try {
        const resposne = await Service.verifyAirdropOtp(payload);
        return resposne
      } catch (err) {
        throw err
      }
    },
    []
  );

  const handleResendCode = useCallback(
    async (payload,{ onSuccess = () => {}, onError = () => {} } = {}) => {
      try {
        const {email} = payload
        if (!email) {
          return window.location.replace("/");
        }
        const response = await Service.resendVerificationCode({ email });
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleSendCodeForClaim = useCallback(
    async (payload,{ onSuccess = () => {}, onError = () => {} } = {}) => {
      try {
        const {email} = payload
        if (!email) {
          return window.location.replace("/");
        }
        const response = await Service.sendVerificationCode({ email });
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleWalletRegister = useCallback(
    async (payload, { onSuccess, onError }) => {
      try {
        const response = await Service.walletRegister(payload);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleBecomeGalleryOwner = useCallback(
    async (payload, { onSuccess = () => {}, onError = () => {} } = {}) => {
      try {
        onSaveEmail(payload)
        const response = await Service.becomeGalleryOwner(payload);
        onSignUpSuccess(response);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    [onSignUpSuccess,onSaveEmail]
  );

  const handleRegisterAsGalleryOwner = useCallback(
    async (
      payload,
      {
        onSuccess = (data) => {
          return data;
        },
        onError = (data) => {
          return data;
        },
      } = {}
    ) => {
      try {
        onSaveEmail(payload)
        const response = await Service.registerAsGalleryOwner(payload);
        onSignUpSuccess(response);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    [onSignUpSuccess,onSaveEmail]
  );

  const handleAddUserWallet = useCallback(
    async (payload, { onSuccess = () => {}, onError = () => {} } = {}) => {
      try {
        const response = await Service.addWallet(payload);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleUpdate = useCallback(
    async (id, payload) => {
      try {
        const response = await Service.update(id, payload);
        onUpdateSuccess(response);
      } catch (err) {
        throw err;
      }
    },
    [onUpdateSuccess]
  );

  const handleGetDetails = useCallback(
    async(id)=>{
      try{
        const response = await Service.getUserDetails(id);
        onUpdateSuccess(response);
        return response.user;
      }
      catch(err){
        throw err;

      }
    },[onUpdateSuccess]
  )
  return (
    <AuthContext.Provider
      value={{
        onSignUpSuccess,
        handleSignUp,
        handleVerifyOtp,
        handleLoginIn,
        handleWalletLogin,
        handleWalletRegister,
        handleAddUserWallet,
        handleBecomeGalleryOwner,
        onOtpVerified,
        handleResendCode,
        handleUpdate,
        handleRegisterAsGalleryOwner,
        handleSendCodeForClaim,
        handleVerifyOtpForAirdrop,
        handleGetDetails
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// withAuthContext and useAuthContext does the same thing in different manner
// export function withAuthContext(Component) {
//   return function contextComponent(props) {
//     return (
//       <AuthContext.Consumer>
//         {(context) => <Component {...props} context={context} />}
//       </AuthContext.Consumer>
//     );
//   };
// }

export const useAuthContext = () => useContext(AuthContext);
