import { useMemo } from "react";
import galleryFactoryAbi from "config/abi/GalleryFactory.json";
import nftAbi from "config/abi/NFT.json";
import { getWeb3 } from "utils/web3";
import { useSettingsContext } from "modules/settings/context";

export const useLibrary = (networkId) => {
  return useMemo(() => {
    const web3 = getWeb3(networkId);
    return web3;
  }, [networkId]);
};

export const useContract = (abi, address, networkId, contractOptions = {}) => {
  const library = useLibrary(networkId);
  return useMemo(() => {
    if (!address) return null;
    if (!abi) return null;
    return new library.eth.Contract(abi, address, contractOptions);
  }, [library, abi, address, contractOptions]);
};

// todo: find a better architecture
export const useGalleryFactoryContract = (networkId) => {
  const settingsContext = useSettingsContext();
  var chainid = networkId;
  const abi = galleryFactoryAbi.abi;
  const contractAddress = settingsContext.galleryFactory[chainid];
  return useContract(abi, contractAddress, chainid);
};

export const useNftContract = (networkId) => {
  const settingsContext = useSettingsContext();
  var chainid = networkId;
  const abi = nftAbi.abi;
  return useContract(abi, settingsContext.nft[chainid], chainid);
};

export const useMarketPlaceContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const abi = settingsContext.abi.marketPlace;
  var chainid = networkId;
  return useContract(abi, settingsContext?.marketPlace[chainid], chainid);
};

export const useGalleryContract = (address, networkId) => {
  const settingsContext = useSettingsContext();
  const abi = settingsContext.abi.gallery;
  return useContract(abi, address || "", networkId);
};

export const useTokenInfoContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const address = settingsContext.tokenInfo[networkId];
  const abi = settingsContext.abi.tokenInfo;
  return useContract(abi, address || "", networkId);
};

export const useAuctionContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const address = settingsContext.auction[networkId];
  const abi = settingsContext.abi.auction;
  return useContract(abi, address || "", networkId);
};

export const useWrappedTokenContract = (networkId) => {
  const settingsContext = useSettingsContext();
  const address = settingsContext.wrappedToken[networkId];
  const abi = settingsContext.abi.wrappedToken;
  return useContract(abi, address || "", networkId);
};
