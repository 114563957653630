import React from "react";
import { Link } from "react-router-dom";
import * as SessionManager from "utils/sessionManager";
import { ROUTE_ROLES } from "constants/app-constants";
import { useThemeContext } from "modules/themes";
import { useThemeChange } from "utils/themeChange";

const Footer = () => {
  const roles = SessionManager.getRoles();
  const user = SessionManager.getCurrentUser();
  const isGalleryOwner = roles.includes(ROUTE_ROLES.GALLERY_OWNER);

  const themeChange = useThemeChange();
  return (
    <div className={themeChange.add("section-2")}>
      <footer
        className="footer-light"
        style={{
          padding: "0px",
          // position: "absolute",
          bottom: 0,
          width: "100%",
          // height: "4rem",
        }}
      >
        {/* <div className="container"> */}
        {/* <div className="row">
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Marketplace</h5>
              <ul>
                <li>
                  <Link to="">All NFTs</Link>
                </li>
                <li>
                  <Link to="">Art</Link>
                </li>
                <li>
                  <Link to="">Music</Link>
                </li>
                <li>
                  <Link to="">Domain Names</Link>
                </li>
                <li>
                  <Link to="">Virtual World</Link>
                </li>
                <li>
                  <Link to="">Collectibles</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Resources</h5>
              <ul>
                <li>
                  <Link to="">Help Center</Link>
                </li>
                <li>
                  <Link to="">Partners</Link>
                </li>
                <li>
                  <Link to="">Suggestions</Link>
                </li>
                <li>
                  <Link to="">Discord</Link>
                </li>
                <li>
                  <Link to="">Docs</Link>
                </li>
                <li>
                  <Link to="">Newsletter</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Community</h5>
              <ul>
                <li>
                  <Link to="">Community</Link>
                </li>
                <li>
                  <Link to="">Documentation</Link>
                </li>
                <li>
                  <Link to="">Brand Assets</Link>
                </li>
                <li>
                  <Link to="">Blog</Link>
                </li>
                <li>
                  <Link to="">Forum</Link>
                </li>
                <li>
                  <Link to="">Mailing List</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-1">
            <div className="widget">
              <h5>Newsletter</h5>
              <p>
                Signup for our newsletter to get the latest news in your inbox.
              </p>
              <form
                action="#"
                className="row form-dark"
                id="form_subscribe"
                method="post"
                name="form_subscribe"
              >
                <div className="col text-center">
                  <input
                    className="form-control"
                    id="txt_subscribe"
                    name="txt_subscribe"
                    placeholder="enter your email"
                    type="text"
                  />
                  <Link to="" id="btn-subscribe">
                    <i className="arrow_right bg-color-secondary"></i>
                  </Link>
                  <div className="clearfix"></div>
                </div>
              </form>
              <div className="spacer-10"></div>
              <small>Your email is safe with us. We don't spam.</small>
            </div>
          </div>
        </div> */}
        {/* </div> */}
        <div className="subfooter" style={{ marginTop: "0px" }}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="de-flex">
                  <div className="de-flex-col">
                    <span onClick={() => window.open("", "_self")}>
                      <span className="copy">
                        &copy; Copyright 2023 - ArtSwap LLC
                      </span>
                    </span>
                  </div>
                  <div className="de-flex-col">
                    <Link to="/privacy">Privacy Policy</Link>
                    <Link
                      to="/terms"
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      Terms & Conditions
                    </Link>
                    <a
                      href="https://medium.com/@artswap"
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        marginLeft: "30px",
                      }}
                    >
                      Blog
                    </a>

                    {user ? (
                      ""
                    ) : (
                      <Link
                        to="/login"
                        style={{
                          marginLeft: "30px",
                        }}
                      >
                        Gallery Login
                      </Link>
                    )}

                    {/* <div
                    className="social-icons"
                    style={{
                      marginLeft: "30px",
                    }}
                  >
                    {/* <span>
                      <a
                        href="https://www.facebook.com/artswap_nft/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-facebook fa-lg"></i>
                      </a>
                    </span>
                    <span>
                      <a
                        href="https://twitter.com/ArtSwap_NFT"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-twitter fa-lg"></i>
                      </a>
                    </span>
                    <span>
                      <a
                        href="https://www.instagram.com/artswap_nft/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram fa-lg"></i>
                      </a>
                    </span>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
