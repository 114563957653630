import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

// import { SUPPORTED_CHAIN_ID } from "constants/global-constants";

export const injected = new InjectedConnector({});
export const walletlink = new WalletLinkConnector({
  url: `https://rpc-mumbai.maticvigil.com`,
  appName: "coinbase wallet"
});

// supportedChainIds: [...SUPPORTED_CHAIN_ID],
