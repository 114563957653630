import axios from "../../utils/requestInstance";

import { API } from "../../constants";

const {
  USER: {
    REGISTER,
    LOGIN,
    VERIFY_OTP,
    WALLET_LOGIN,
    WALLET_REGISTER,
    ADD_USER_WALLET,
    BECOME_GALLERY_OWERR,
    RESEND_VERIFICATION_CODE,
    UPDATE,
    REGISTER_AS_GALLERY_OWNER,
    SEND_VERIFICATION_CODE,
    VERIFY_AIRDROP_OTP,
    GET_DETAILS
  },
} = API;

export const resendVerificationCode = async (payload = {}) => {
  const response = await axios.post(RESEND_VERIFICATION_CODE, payload);
  return response.data;
};
export const sendVerificationCode = async (payload = {}) => {
  const response = await axios.post(SEND_VERIFICATION_CODE, payload);
  return response.data;
};
export const becomeGalleryOwner = async (payload = {}) => {
  const resposne = await axios.patch(BECOME_GALLERY_OWERR, payload);
  return resposne.data;
};
export const registerAsGalleryOwner = async (payload = {}) => {
  const response = await axios.post(REGISTER_AS_GALLERY_OWNER, payload);
  return response.data;
};
export const walletRegister = async (payload = {}) => {
  const resposne = await axios.post(WALLET_REGISTER, payload);
  return resposne.data;
};

export const walletLogin = async (payload = {}) => {
  const resposne = await axios.post(WALLET_LOGIN, payload);
  return resposne.data;
};

export const addWallet = async (payload = {}) => {
  const response = await axios.patch(ADD_USER_WALLET, payload);
  return response.data;
};

export const signUp = async (payload = {}) => {
  const response = await axios.post(REGISTER, payload);
  return response.data;
};

export const verifyOtp = async (payload = {}) => {
  const response = await axios.post(VERIFY_OTP, payload);
  return response.data;
};
export const verifyAirdropOtp = async (payload = {}) => {
  const response = await axios.post(VERIFY_AIRDROP_OTP, payload);
  return response;
};
export const login = async (payload = {}) => {
  const response = await axios.post(LOGIN, payload);
  return response.data;
};
export const update = async (id, payload) => {
  const response = await axios.put(`${UPDATE}/${id}`, payload);
  return response.data;
};

export const getUserDetails = async(id)=>{
  const response = await axios.get(`${GET_DETAILS}/${id}`);
  return response.data
}
