import axios from "../../utils/requestInstance";
import { API } from "../../constants";

const {
  SEARCH: { LISTDATA,GETOWNER },
} = API;

export const listData = async (payload) => {
  const response = await axios.get(LISTDATA, payload);
  return response.data;
};
export const getOwner = async(payload)=>{
  const response = await axios.get(GETOWNER,payload);
   return response.data
}