const BASE_URL = process.env.REACT_APP_SERVER_URL;
const IPFS_URL = process.env.REACT_APP_IPFS_URL;
const CLOUDFARE_URL = process.env.REACT_APP_CLOUDFARE_URL;
const HCAPTCHA_SITE_KEY = process.env.REACT_APP_HCAPTCHA_SITE_KEY;
const SELECT_URL = process.env.REACT_APP_SELECT_COUNTRY_STATE_URL;
const GAS_POLY_URL = process.env.REACT_APP_GAS_URL;
const ZIPCODE_URL = process.env.REACT_APP_SELECT_ZIPCODE_URL;

const SERVER_URL = process.env.REACT_APP_SERVER;
const GAS_URL = {
  11155111: process.env.REACT_APP_ETHEREUM_GAS_URL,
  80001: process.env.REACT_APP_POLY_GAS_URL,
  137: process.env.REACT_APP_POLY_GAS_URL,
  97: process.env.REACT_APP_BINANCE_GAS_URL,
  56: process.env.REACT_APP_BINANCE_GAS_URL,
  1: process.env.REACT_APP_ETHEREUM_GAS_URL,
};

module.exports = {
  IPFS_URL,
  BASE_URL,
  SELECT_URL,
  CLOUDFARE_URL,
  HCAPTCHA_SITE_KEY,
  GAS_POLY_URL,
  GAS_URL,
  ZIPCODE_URL,
  SERVER_URL,
  CAPTCHA: {
    VERIFY_CAPTCHA: "/verifyCaptcha",
  },
  USER: {
    LOGIN: "/users/login",
    REGISTER: "/users/register",
    VERIFY_OTP: "/users/verify-login",
    WALLET_LOGIN: "/users/wallet-login",
    WALLET_REGISTER: "/users/wallet-register",
    ADD_USER_WALLET: "/users/add-user-wallet",
    BECOME_GALLERY_OWERR: "/users/become-gallery-owner",
    REGISTER_AS_GALLERY_OWNER: "/users/register_as_gallery_owner",
    RESEND_VERIFICATION_CODE: "/users/resend-otp",
    UPDATE: "/users",
    SEND_VERIFICATION_CODE: "/users/otp-airdrop",
    VERIFY_AIRDROP_OTP: "/users/verify-otp",
    GET_DETAILS: "/users/details",
  },
  ARTIST: {
    ADD: "/artist",
    GETBYGALLERY: "/artist/galleries",
    GETDETAILS: "/artist",
    GETGALLERYDETAILS: "/artist/gallery-details",
    UPDATE: "/artist",
    LIST: "/artist",
    SOLD:"/artist/sold"
  },
  GALLERY: {
    ADD: "/gallery",
    LIST: "/gallery",
    DELETE: "/gallery",
    PROFILEUPDATE: "/gallery/profileimage",
    COVERUPDATE: "/gallery/coverimage",
    UPDATE: "/gallery",
    GETDETAILS: "/gallery",
    GETDETAILSBYVANITYURL: "/gallery/detail",
    ADMIN: "/gallery/admins",
    GETGALLERY: "/gallery/token",
    UPDATECONTRACTADDRESS: "/gallery/contractaddress",
    GETSOLDNFTS: "gallery/sold",
    LISTNETWORKS: "/networks",
    GETNETWORKDETAILS: "/networks/chain",
    GET_FEATURED_GALLERIES: "/gallery/admin/featured",
  },
  GALLERY_ARTIST_RELN: {
    ADD: "/gallery_artist_reln",
    LIST_ASSOCIATED_ARTISTS: "/gallery_artist_reln/list_gallery_artist",
    LIST_ASSOCIATED_GALLERIES: "/gallery_artist_reln/list_artists_gallery",
    REMOVE_ASSOCIATED_GALLERIES: "/gallery_artist_reln/remove_gallery_artist",
  },
  COLLECTION: {
    ADD: "/collection",
    LIST: "/collection",
    DELETE: "/collection",
    PROFILEUPDATE: "/collection/profileimage",
    COVERUPDATE: "/collection/coverimage",
    UPDATE: "/collection",
    GETDETAILS: "/collection",
    GETBYGALLERY: "/collection/galleries",
    GETBYUSER: "/collection/user",
    FEATURED_COLLECTIONS: "/collection/admin/featured",
  },
  NFT: {
    ADD: "/nft",
    LIST: "/nft",
    GETDETAILS: "/nft",
    UPDATE: "/nft",
    REMOVE: "/nft",
    REMOVECOLLECTION: "/nft/remove_collection",
    SELLNFT: "nft/sell_nft",
    BUYNFT: "nft/buy_nft",
    MINTNFT: "nft/mint_nft",
    GETFIAT: "nft/get_fiat_value",
    TRANSFERNFT: "/nft/transfer",
    BURNNFT: "/nft/burn",
    CANCELSELL: "/nft/cancel",
    AIRDROPNFT: "/airdrop",
    CLAIMAIRDROP: "/airdrop/accept",
    getTransactionHistory: "/nft/getHistory",
    getBatchTransactionHistory:"/nft/getbatchHistory",
    getSoldHistory: "/nft/getSoldHistory",
    LAZY_MINTED: "/nft/lazy_mint",
    CREATE_BULK_MINT: "/nft/create-bulk-mint",
    CANCEL_LAZY_MINTED:"/nft/lazy_mint/cancel"
  },
  FEATUREDNFTS: {
    LISTNFTS: "/nft/admin/featured",
  },
  SEARCH: {
    LISTDATA: "/search",
    GETOWNER: "/search/owner",
  },
  SETTINGS: {
    LIST_SETTINGS: "/settings",
    GET_SETTINGS_BY_ID: "/settings",
    GET_SETTINGS_BY_CHAINID: "/settings/chain",
    GET_SETTINGS_BY_CHAINID_VERSION: "/settings/chain",
  },
  PHYSICAL_TWIN_MEDIUM: {
    LIST_CATEGORIES: "/physicalMedium/category",
    LIST_SUBCATEGORIES_BY_PARENT: "/physicalMedium/subcategory/parent",
  },
  TAGS: {
    LIST_TAGS: "/tags",
    REGSITER_TAG: "/tags",
  },
  TRANSACTION: {
    ADD_TRANSACTION: "/transactions",
  },
  NOTIFICATION: {
    UPDATE_NOTIFICATION: "/notifications",
  },
  CMS: {
    GET_PAGE_DETAIL: "/template/page",
  },
  PRICE:{
    GET_PRICE_PAGE:"/pricing"
  }
};
