import React from "react";
import Spinner from "react-bootstrap/Spinner";
import { createGlobalStyle } from "styled-components";

const GLobalStyle = createGlobalStyle`
.mr-1{
  margin-right:1rem
}
`;
const Loading = (props) => {
  const { loadingMessage, style = {} } = props;

  return (
    <>
      <GLobalStyle />
      <div className="btn btn-main mr2" disabled style={style}>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
          className="mr-1"
        />
        {loadingMessage?.length ? loadingMessage : "Loading..."}
      </div>
    </>
  );
};

export default Loading;
