import React, { useContext, createContext, useCallback } from "react";
import * as Service from "./service";

const noOp = (res) => res;

const SearchContext = createContext(null);

export const SearchContextProvider = ({ children }) => {
  const handleSearch = useCallback(
    async (query, { onSuccess = noOp, onError = noOp } = {}) => {
      try {
        const response = await Service.listData(query);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  const handleGetOwner = useCallback(
    async (query, { onSuccess = noOp, onError = noOp } = {}) => {
      try {
        const response = await Service.getOwner(query);
        onSuccess(response);
      } catch (err) {
        onError(err);
      }
    },
    []
  );

  return (
    <SearchContext.Provider
      value={{
        handleSearch,
        handleGetOwner,
      }}>
      {children}
    </SearchContext.Provider>
  );
};

export const useSearchContext = () => useContext(SearchContext);
