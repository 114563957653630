import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from "react";
import Web3 from "web3";
import { useAuth } from "hooks/useWalletConnect";
import { useWeb3React } from "@web3-react/core";
import { COMMON_BASE_TOKENS } from "constants/chain.constants";
import CustomModal from "components/components/Modal";
import * as SessionManager from "utils/sessionManager";
import { compareWalletAdress } from "utils/web3";
import { DEFAULT_CHAIN_ID, SIGNING_MESSAGE } from "constants/global-constants";
import { IPFS_URL } from "constants/api-constants";
import { useThemeChange } from "utils/themeChange";
const WalletContext = createContext({});

const noOp = () => {};

export const WalletContextProvider = ({ children }) => {
  const { connectMetaMask, connectCoinbase, logOut } = useAuth();
  const { library, account } = useWeb3React();
  const [modalOpen, setModalOpen] = useState(false);
  const user = SessionManager.getCurrentUser();
  const themeChange = useThemeChange();

  const connectWallet = useCallback(
    async (providerName, { onSuccess = noOp, onError = noOp } = {}) => {
      try {
        await connectMetaMask(providerName);
        return onSuccess();
      } catch (err) {
        onError();
      }
    },
    [connectMetaMask]
  );

  const logout = useCallback((e) => {
    e.preventDefault();
    SessionManager.clearAll();
    window.location.replace("/");
  }, []);

  const connectCoinbaseWallet = useCallback(
    async ({ onSuccess = noOp, onError = noOp } = {}) => {
      try {
        await connectCoinbase();
        return onSuccess(library, account);
      } catch (err) {
        onError();
      }
    },
    [connectCoinbase, account, library]
  );

  const getDateSignWithMetamask = useCallback(async () => {
    if (!window?.ethereum) throw Error("Metamask not found");
    const provider = new Web3(window.ethereum);
    const date = Date.now();
    const hash = provider.utils.sha3(date.toString());
    const signer = await provider.eth.getAccounts();
    let signature = await provider.eth.personal.sign(
      `${SIGNING_MESSAGE}${hash}`,
      signer[0]
    );
    signature = `${date}..${SIGNING_MESSAGE}..${hash}..${signature}`;
    return signature;
  }, []);

  const getDateSignature = useCallback(async () => {
    if (!library || !account) return null;
    const hash = library.utils.sha3(Date.now().toString());
    const signature = await library.eth.personal.sign(
      `${SIGNING_MESSAGE}${hash}`,
      account
    );
    return `${Date.now()}..${SIGNING_MESSAGE}..${hash}..${signature}`;
  }, [account, library]);

  const getSignaureForLazyMint = useCallback(
    async (minPrice, baseUri, timeStamp) => {
      if (!library || !account) return null;
      const hash = library.utils.sha3(
        `${minPrice}:${IPFS_URL}/${baseUri}:${timeStamp}`
      );

      const signature = await library.eth.personal.sign(hash, account);
      return signature;
    },
    [account, library]
  );

  const getChainToken = useCallback((networkId) => {
    if (!networkId) return COMMON_BASE_TOKENS[DEFAULT_CHAIN_ID];
    return (
      COMMON_BASE_TOKENS[networkId] || COMMON_BASE_TOKENS[DEFAULT_CHAIN_ID]
    );
  }, []);

  const hanldeAccountChange = useCallback(async () => {
    if (!account) return;
    if (!user?.wallet_address) return;

    const isWalletMatched = await compareWalletAdress(
      account,
      user.wallet_address
    );
    setModalOpen(!isWalletMatched);
  }, [account, user]);

  useEffect(() => {
    hanldeAccountChange();
  }, [hanldeAccountChange]);

  return (
    <WalletContext.Provider
      value={{
        connectMetaMask,
        connectCoinbaseWallet,
        logOut,
        connectWallet,
        getDateSignWithMetamask,
        getChainToken,
        getDateSignature,
        getSignaureForLazyMint,
      }}
    >
      <CustomModal
        show={modalOpen}
        onHide={() => setModalOpen((prev) => !prev)}
      >
        <div className={themeChange.add("section-2")}>
          <h4>Wallet Mismatch</h4>
          <p>
            Wallet registered in your current account doesnot match the
            connected account in Metamask.
          </p>
          <div className="d-flex justify-content-center">
            <input
              onClick={logout}
              id="send_message"
              value="Logout"
              className="btn btn-main color-2"
            />
          </div>
        </div>
      </CustomModal>
      {children}
    </WalletContext.Provider>
  );
};

// withWalletContext and useWalletContext does the same thing in different manner
// export function withWalletContext(Component) {
//   return function contextComponent(props) {
//     return (
//       <WalletContext.Consumer>
//         {(context) => <Component {...props} context={context} />}
//       </WalletContext.Consumer>
//     );
//   };
// }

export const useWalletContext = () => useContext(WalletContext);
