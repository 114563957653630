import axios, { gasAPIInstance } from "../../utils/requestInstance";
import { API } from "../../constants";
import { EXPLORER_KEY } from "constants/global-constants";

const {
  NFT: {
    ADD,
    LIST,
    GETDETAILS,
    UPDATE,
    REMOVE,
    REMOVECOLLECTION,
    SELLNFT,
    BUYNFT,
    MINTNFT,
    GETFIAT,
    TRANSFERNFT,
    BURNNFT,
    CANCELSELL,
    AIRDROPNFT,
    CLAIMAIRDROP,
    getTransactionHistory,
    getBatchTransactionHistory,
    getSoldHistory,
    LAZY_MINTED,
    CANCEL_LAZY_MINTED,
    CREATE_BULK_MINT
  },
  GALLERY: { UPDATECONTRACTADDRESS, GETNETWORKDETAILS, LISTNETWORKS },
  FEATUREDNFTS: { LISTNFTS },
  TRANSACTION: { ADD_TRANSACTION },
  NOTIFICATION: { UPDATE_NOTIFICATION },
} = API;

export const addNft = async (payload) => {
  const response = await axios.post(ADD, payload);
  return response.data;
};

export const handleSellNft = async ({ nftId, body }) => {
  const response = await axios.patch(`${SELLNFT}/${nftId}`, body);
  return response.data;
};

export const handleBuyNft = async ({ nftId, body }) => {
  const response = await axios.patch(`${BUYNFT}/${nftId}`, body);
  return response.data;
};

export const listNft = async (params) => {
  const response = await axios.get(LIST, { params });
  return response.data;
};

export const getDetails = async (id) => {
  const response = await axios.get(`${GETDETAILS}/${id}`);
  return response.data;
};

export const update = async (id, payload) => {
  const response = await axios.put(`${UPDATE}/${id}`, payload);
  return response.data;
};

export const remove = async (id) => {
  const response = await axios.delete(`${REMOVE}/${id}`);
  return response;
};

export const removeFromCollection = async (params) => {
  const response = await axios.patch(
    `${REMOVECOLLECTION}/${params?.nftId}/${params?.collectionId}`
  );

  return response.data;
};

export const updateGalleryContractAddress = async ({ id, body }) => {
  const response = await axios.put(`${UPDATECONTRACTADDRESS}/${id}`, body);
  return response.data;
};

export const getFiatValue = async (params = {}) => {
  const response = await axios.get(GETFIAT, { params });
  return response.data;
};

export const burnNft = async (id) => {
  const response = await axios.put(`${BURNNFT}/${id}`);
  return response.data;
};

export const transferNft = async ({ nftId, body }) => {
  const response = await axios.put(`${TRANSFERNFT}/${nftId}`, body);
  return response.data;
};

export const airdropNft = async (body) => {
  const response = await axios.post(AIRDROPNFT, body);
  return response.data;
};

export const mintandairdropNft = async ({ nftId, body }) => {
  const response = await axios.patch(`${MINTNFT}/${nftId}`, body);
  return response.data;
};

export const cancelSell = async (id) => {
  const response = await axios.put(`${CANCELSELL}/${id}`);
  return response.data;
};

export const getHistory = async (params = {}) => {
  const response = await axios.get(getTransactionHistory, { params });
  return response.data;
};

export const getBatchHistory = async (params = {}) => {
  const response = await axios.get(getBatchTransactionHistory, { params });
  return response.data;
};

export const getsoldHistory = async (params = {}) => {
  const response = await axios.get(getSoldHistory, { params });
  return response.data;
};

export const listFeaturedNFT = async (params = {}) => {
  const response = await axios.get(LISTNFTS, { params });
  return response.data;
};

export const listPendingNft = async () => {
  const response = await axios.get(AIRDROPNFT);
  return response.data;
};

export const claimPendingNft = async (id) => {
  const response = await axios.post(`${CLAIMAIRDROP}/${id}`);
  return response;
};

export const getNetworkList = async () => {
  const response = await axios.get(LISTNETWORKS);
  return response.data;
};

export const getNetworkDetails = async (networkid) => {
  const response = await axios.get(`${GETNETWORKDETAILS}/${networkid}`);
  return response.data;
};

export const gasPriceList = async () => {
  const logsPramas = {
    module: "gastracker",
    action: "gasoracle",
    apikey: EXPLORER_KEY,
  };
  const logs = await gasAPIInstance.get(`/api`, { params: logsPramas });
  return logs.data;
};

export const addTransaction = async (payload) => {
  const response = await axios.post(ADD_TRANSACTION, payload);
  return response;
};

export const updateNotification = async (id) => {
  const response = await axios.put(`${UPDATE_NOTIFICATION}/${id}`);
  return response.data;
};

export const lazyMinting = async ({ nftId, body }) => {
  const response = await axios.patch(`${LAZY_MINTED}/${nftId}`, body);
  return response.data;
};

export const cancelLazyMinting = async (id)=>{
  const response = await axios.put(`${CANCEL_LAZY_MINTED}/${id}`);
  return response.data
}

export const buyBulkNft = async (id, payload) => {
  const response = await axios.post(`${CREATE_BULK_MINT}/${id}`, payload);
  return response.data;
};
