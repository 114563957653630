import * as types from "./types";

const reducer = (state, action) => {
  let result = action.res;
  switch (action.type) {
    case types.LIST_SETTINGS_SUCCESS:
      return {
        ...state,
        // settings: result,
      };
    case types.GET_SETTINGS_BY_CHAINID:
      return {
        ...state,
        ...result,
      };
    default:
      return state;
  }
};

export default reducer;
