export const getCurrentUser = () => {
  let user = null;
  let data = localStorage.getItem("current_user");
  if (data) user = JSON.parse(data);
  return user;
};

export const removeOtpDetails = () => localStorage.removeItem("otpDetails");

export const saveGallerySetupStage = (stage) =>
  localStorage.setItem("gallerySetupStage", JSON.stringify(stage));

export const getGallerySetupStage = () =>
  JSON.parse(localStorage.getItem("gallerySetupStage"));

export const removeGallerySetupStage = () =>
  localStorage.removeItem("gallerySetupStage");

export const saveRoles = (roles) =>
  localStorage.setItem("roles", JSON.stringify(roles));
export const getRoles = () => {
  let roles = [];
  const data = localStorage.getItem("roles");
  if (data) roles = JSON.parse(data);
  return roles;
};
export const saveOtpDetails = (details) =>
  localStorage.setItem("otpDetails", JSON.stringify(details));

export const getOtpDetails = () => {
  let otp = null;
  let data = localStorage.getItem("otpDetails");
  if (data) otp = JSON.parse(data);
  return otp;
};

export const saveCurrentUser = (userData) =>
  localStorage.setItem("current_user", JSON.stringify(userData));

export const getAccessToken = () => localStorage.getItem("access_token");

export const saveAccessToken = (access_token) =>
  localStorage.setItem("access_token", access_token);
export const clearAll = () => localStorage.clear();

export const saveAuthSignature = (auth_signature) =>
  localStorage.setItem("auth_signature", auth_signature);

export const removeAuthSignature = () =>
  localStorage.removeItem("auth_signature");

export const getAuthSignature = () => localStorage.getItem("auth_signature");

export const saveEmail = (email) =>
  localStorage.setItem("email_to_verify", email);

export const getEmail = () => localStorage.getItem("email_to_verify");

export const removeEmail = () => localStorage.removeItem("email_to_verify");

export const saveAuth =(auth)=>localStorage.setItem("auth",auth);
export const isAdmin = () => {
  const currentUser = getCurrentUser();
  return currentUser?.roles.includes("Admin");
};
