import React from "react";
import WalletCanvas from "./WalletCanvas";
import { AuthContextProvider } from "modules/authenctication/context";

export default function Index(props) {
  return (
    <AuthContextProvider>
      <WalletCanvas {...props} />
    </AuthContextProvider>
  );
}
