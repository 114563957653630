import axios from "axios";
import * as SessionManager from "./sessionManager";
import { API } from "../constants";
// import { useWeb3React } from "@web3-react/core";

const access_token = SessionManager.getAccessToken();


const instance = axios.create({
  baseURL: API.BASE_URL,
  headers: {
    "Content-Type": "application/json",
    access_token: access_token || "",
  },
});

//axios instance for select countries and states
export const selectAPIInstance = axios.create({
  baseURL: API.SELECT_URL,
  headers: {
    "X-CSCAPI-KEY": process.env.REACT_APP_SELECT_COUNTRY_STATE_TOKEN,
  }
})
//axios instance for polygon gas estimation 
export const gasAPIInstance= (chainId)=> axios.create({
  baseURL:API.GAS_URL[chainId?.toString()],
})
//axios instance for zipcode selection
export const selectZipCodeAPI = axios.create({
  baseURL: API.ZIPCODE_URL,
})
export default instance;
