import React, { useCallback, useMemo, useState, useEffect } from "react";
import OffCanvasComponent from "components/components/OffCanvasComponent";
import { useWeb3React } from "@web3-react/core";
import { useWalletContext } from "modules/wallet/context";
import * as SessionManager from "utils/sessionManager";
import { useToastContext } from "modules/toast/context";
import { useAuthContext } from "modules/authenctication/context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { COMMON_BASE_TOKENS } from "constants/chain.constants";
import {
  DEFAULT_CHAIN_ID,
  REFRESH_TIME,
  SIGNING_MESSAGE,
  PRICE_CONSTANTS,
} from "constants/global-constants";
import { useThemeContext } from "modules/themes";
import { useThemeChange } from "utils/themeChange";
import { getWeb3 } from "utils/web3";

import Web3 from "web3";
import CustomModal from "components/components/Modal";
import { PolygonMatic } from "components/components/Icons";
import { WALLET_LOGIN_RESPONSE_STATUS } from "constants/response-constants";
import ClickToCopy from "components/components/ClickToCopy/ClickToCopy";
import { useWrappedTokenContract } from "hooks/useContract";
import WrappedModal from "./WrappedModal";

export default function WalletCanvas(props) {
  const { theme } = useThemeContext();
  const { library, account, deactivate, chainId } = useWeb3React();
  // const web3Ins = useWeb3(chainId);

  const navigate = useNavigate();
  const location = useLocation();
  const {
    connectWallet,
    getDateSignWithMetamask,
    logOut: disconnectWallet,
    connectCoinbaseWallet,
  } = useWalletContext();
  const [userBalance, setUserBalance] = useState("");
  const [wrappedTokenBalance, setWrappedTokenBalance] = useState("");
  const { showError, showSuccess } = useToastContext();
  const themeChange = useThemeChange();
  const [registerWalletModal, setRegiserWalletModal] = useState(false);
  const toggleRegisterWalletModal = () =>
    setRegiserWalletModal((prev) => !prev);
  const { handleWalletLogin, handleAddUserWallet } = useAuthContext();
  const wrappedTokenContract = useWrappedTokenContract(chainId);

  const [isChecked, setIsChecked] = useState(false);
  const [exchangeModel, setExchangeModel] = useState(false);

  const toggleExchangeModel = () => {
    props.handleClose();
    setExchangeModel((prev) => !prev);
    userWrappedBalance();
  };

  // const [actionCopy, setActionCopy] = useState(false);
  // const handleCopy = () => {
  //   setActionCopy(true);
  //   copyAddress();
  //   setTimeout(() => {
  //     setActionCopy(false);
  //   }, 800);
  // };

  const walletResponse = useCallback(async () => {
    // try {
    //   await connectCoinbaseWallet({
    //     onSuccess: async () => {
    try {
      const auth_signature = SessionManager.getAuthSignature();
      const payload = { auth_signature };
      const response = await handleWalletLogin(payload);
      const { status, token, user } = response;
      const roles = user?.roles || [];
      const auth = localStorage.getItem("auth");
      if (status === WALLET_LOGIN_RESPONSE_STATUS.NEW_USER) {
        deactivate();
        localStorage.setItem("authentication", auth);

        if (auth === "Metamask") {
          localStorage.removeItem("auth_signature");
        }

        return navigate("/walletRegister", {
          state: {
            from: location?.search
              ? `${location?.pathname}${location?.search}`
              : location.pathname,
          },
        });
      }
      if (registerWalletModal) toggleRegisterWalletModal();
      SessionManager.saveAccessToken(token);
      SessionManager.saveCurrentUser(user);
      SessionManager.saveRoles(roles);
    } catch (err) {
      if (err?.response?.data?.message === "Wallet mismatch")
        showError(err?.response?.data?.message || "Wallet Mismatch");
      if (err?.response?.data?.message === "Wallet not found") {
        showError(err?.response?.data?.message || "Wallet not found");
        navigate("/addUserWallet", {
          state: {
            from: location?.search
              ? `${location?.pathname}${location?.search}`
              : location.pathname,
          },
        });
        deactivate();
        return;
        //         toggleRegisterWalletModal();
        // disconnectWallet();
        // return;
      }
      deactivate();
    }

    //   onError: () => {
    //     console.log("error")
    //     account && deactivate();
    //   },
    // });
    //   console.log("connect")
    // } catch (err) {
    //   console.log(err);
    // }
  }, [deactivate, handleWalletLogin]);

  const auth = localStorage.getItem("auth");

  const handleConnectWallet = async () => {
    if (account) return;
    const auth_signature = SessionManager.getAuthSignature();
    if (!account && auth_signature) {
      SessionManager.removeAuthSignature();
    }
    return connectWallet({
      onSuccess: async () => {
        await walletResponse();
      },
      onError: () => {
        account && deactivate();
      },
    });
  };

  const handleCoinbaseConnect = useCallback(async () => {
    if (account) return;
    // await walletResponse();
    try {
      await connectCoinbaseWallet({
        onSuccess: async () => {
          await walletResponse();
          // try {
          //   const auth_signature = SessionManager.getAuthSignature();
          //   const payload = { auth_signature };
          //   const response = await handleWalletLogin(payload);
          //   console.log({response})
          //   const { status, token, user } = response;
          //   const roles = user?.roles || [];
          //   const auth = localStorage.getItem("auth");
          //   console.log(auth)

          //   if (status === WALLET_LOGIN_RESPONSE_STATUS.NEW_USER) {
          //     deactivate();
          //     localStorage.setItem("authentication", auth);

          //     // localStorage.removeItem("auth_signature");
          //     return navigate("/walletRegister", {
          //       state: {
          //         from: location?.search
          //           ? `${location?.pathname}${location?.search}`
          //           : location.pathname,
          //       },
          //     });
          //   }
          //   toggleRegisterWalletModal();
          //   SessionManager.saveAccessToken(token);
          //   SessionManager.saveCurrentUser(user);
          //   SessionManager.saveRoles(roles);
          // } catch (err) {
          //   if (err?.response?.data?.message === "Wallet mismatch")
          //     showError(err?.response?.data?.message || "Wallet Mismatch");
          //   if (err?.response?.data?.message === "Wallet not found") {
          //     showError(err?.response?.data?.message || "Wallet not found");
          //     navigate("/addUserWallet", {
          //       state: {
          //         from: location?.search
          //           ? `${location?.pathname}${location?.search}`
          //           : location.pathname,
          //       },
          //     });
          //     deactivate();
          //     return;
          //     //         toggleRegisterWalletModal();
          //     // disconnectWallet();
          //     // return;
          //   }
          //   deactivate();
          // }
        },
        onError: () => {
          console.log("error");
          account && deactivate();
        },
      });
      console.log("connect");
    } catch (err) {
      console.log(err);
    }
  }, [account, walletResponse]);

  const getDateSignature = useCallback(async () => {
    if (!library || !account) return null;
    const hash = library.utils.sha3(Date.now().toString());
    const signature = await library.eth.personal.sign(
      `${SIGNING_MESSAGE}${hash}`,
      account
    );
    return `${Date.now()}..${SIGNING_MESSAGE}..${hash}..${signature}`;
  }, [account, library]);

  const handleRegisterUserWallet = useCallback(async () => {
    let auth_signature = null;
    auth_signature = SessionManager.getAuthSignature();
    const auth = localStorage.getItem("auth");

    if (!auth_signature) {
      if (auth === "WalletLink") {
        try {
          auth_signature = await getDateSignature();
        } catch (err) {
          deactivate();
        }
      } else {
        auth_signature = await getDateSignWithMetamask();
      }
    }

    const payload = { auth_signature };
    return handleAddUserWallet(payload, {
      onSuccess: async (response) => {
        const { token, user } = response;
        const { roles } = user;
        SessionManager.saveRoles(roles);
        SessionManager.saveAccessToken(token);
        SessionManager.saveCurrentUser(user);
        SessionManager.saveAuthSignature(auth_signature);
        showSuccess("Successfully registered wallet");

        if (auth === "WalletLink") {
          await connectCoinbaseWallet({
            onSuccess: async () => {
              await walletResponse();
              // try {
              //   const auth_signature = SessionManager.getAuthSignature();
              //   const payload = { auth_signature };
              //   const response = await handleWalletLogin(payload);
              //   const { status, token, user } = response;
              //   const roles = user?.roles || [];
              //   const auth = localStorage.getItem("auth");

              //   if (status === WALLET_LOGIN_RESPONSE_STATUS.NEW_USER) {
              //     deactivate();
              //     localStorage.setItem("authentication", auth);

              //     // localStorage.removeItem("auth_signature");
              //     return navigate("/walletRegister", {
              //       state: {
              //         from: location?.search
              //           ? `${location?.pathname}${location?.search}`
              //           : location.pathname,
              //       },
              //     });
              //   }
              //   toggleRegisterWalletModal();
              //   SessionManager.saveAccessToken(token);
              //   SessionManager.saveCurrentUser(user);
              //   SessionManager.saveRoles(roles);
              // } catch (err) {
              //   if (err?.response?.data?.message === "Wallet mismatch")
              //     showError(err?.response?.data?.message || "Wallet Mismatch");
              //   if (err?.response?.data?.message === "Wallet not found") {
              //     showError(err?.response?.data?.message || "Wallet not found");
              //     navigate("/addUserWallet", {
              //       state: {
              //         from: location?.search
              //           ? `${location?.pathname}${location?.search}`
              //           : location.pathname,
              //       },
              //     });
              //     deactivate();
              //     return;
              //     //         toggleRegisterWalletModal();
              //     // disconnectWallet();
              //     // return;
              //   }
              //   deactivate();
              // }
            },
            onError: () => {
              account && deactivate();
            },
          });
        } else {
          await connectWallet({
            onSuccess: async () => {
              await walletResponse();
              // try {
              //   const auth_signature = SessionManager.getAuthSignature();
              //   const payload = { auth_signature };
              //   const response = await handleWalletLogin(payload);
              //   const { status, token, user } = response;
              //   const roles = user?.roles || [];
              //   if (status === WALLET_LOGIN_RESPONSE_STATUS.NEW_USER) {
              //     deactivate();
              //     localStorage.removeItem("auth_signature");
              //     return navigate("/walletRegister", {
              //       state: {
              //         from: location?.search
              //           ? `${location?.pathname}${location?.search}`
              //           : location.pathname,
              //       },
              //     });
              //   }
              //   toggleRegisterWalletModal();
              //   SessionManager.saveAccessToken(token);
              //   SessionManager.saveCurrentUser(user);
              //   SessionManager.saveRoles(roles);
              // } catch (err) {
              //   if (err?.response?.data?.message === "Wallet mismatch")
              //     showError(err?.response?.data?.message || "Wallet Mismatch");
              //   if (err?.response?.data?.message === "Wallet not found") {
              //     showError(err?.response?.data?.message || "Wallet not found");
              //     navigate("/addUserWallet", {
              //       state: {
              //         from: location?.search
              //           ? `${location?.pathname}${location?.search}`
              //           : location.pathname,
              //       },
              //     });
              //     deactivate();
              //     return;
              //     //         toggleRegisterWalletModal();
              //     // disconnectWallet();
              //     // return;
              //   }
              //   deactivate();
              // }
            },
            onError: () => {
              account && deactivate();
            },
          });
        }
        if (location.state?.from) {
          window.location.replace(location.state?.from);
        } else if (location.pathname === "/login") {
          window.location.replace("/profile");
        } else {
          window.location.reload();
        }
        return;
      },
      onError: (err) => {
        SessionManager.removeAuthSignature();
        showError(err?.response?.data?.message || "Could not connect wallet");
        disconnectWallet();
        deactivate();
      },
    });
  }, [
    getDateSignWithMetamask,
    handleAddUserWallet,
    showError,
    showSuccess,
    deactivate,
    disconnectWallet,
    account,
    getDateSignature,
    // library,
    connectWallet,
    handleWalletLogin,
    navigate,
  ]);
  const onDisconnectWallet = () => {
    const myTheme = localStorage.getItem("theme") || "dark";
    disconnectWallet();
    localStorage.clear();
    localStorage.setItem("theme", myTheme);
    if (window.location.pathname === "/unauthorized") {
      window.location.href = "/";
    } else {
      window.location.reload();
    }
  };
  const requestSignatureLogin = useCallback(async () => {
    try {
      if (!library) return;
      const savedSignature = SessionManager.getAuthSignature();
      if (savedSignature) return;
      const auth_signature = await getDateSignature();
      SessionManager.saveAuthSignature(auth_signature);
      const payload = { auth_signature };
      const response = await handleWalletLogin(payload);
      const { status, token, user } = response;
      const roles = user?.roles || [];
      const auth = localStorage.getItem("auth");
      if (status === WALLET_LOGIN_RESPONSE_STATUS.NEW_USER) {
        deactivate();
        if (auth === "MetaMask") localStorage.removeItem("auth_signature");
        localStorage.setItem("authentication", auth);
        if (auth === "MetaMask") {
          localStorage.removeItem("auth_signature");
        }
        navigate("/walletRegister", {
          state: {
            from: location?.search
              ? `${location?.pathname}${location?.search}`
              : location.pathname,
          },
        });
        return;
      }
      SessionManager.saveAccessToken(token);
      SessionManager.saveCurrentUser(user);
      SessionManager.saveRoles(roles);
      if (
        window.location.pathname === "/login" ||
        window.location.pathname === "/walletRegister"
      ) {
        window.location.replace("/profile");
      } else {
        window.location.reload();
      }

      return;
    } catch (err) {
      const auth = localStorage.getItem("auth");
      if (auth === "MetaMask") {
        disconnectWallet();
      }

      if (err?.response?.data?.message === "Wallet mismatch") {
        showError(err?.response?.data?.message || "Wallet Mismatch");
        SessionManager.removeAuthSignature();
        deactivate();
      }
      if (err?.response?.data?.message === "Wallet not found") {
        toggleRegisterWalletModal();
        SessionManager.removeAuthSignature();
        return;
      }
      if (err?.response?.data?.message === "Wallet already registered") {
        showError(err?.response?.data?.message || "Wallet already registered");
      }
      deactivate();
    }
  }, [
    library,
    getDateSignature,
    deactivate,
    handleWalletLogin,
    location,
    navigate,
    location,
    // showError,
    disconnectWallet,
  ]);
  const handleCancelRegister = () => {
    toggleRegisterWalletModal();
    SessionManager.removeAuthSignature();
    return deactivate();
  };
  const walletAddress = useMemo(
    () =>
      account &&
      `${account?.slice(0, 7)}...${account?.slice(
        account?.length - 4,
        account?.length
      )}`,
    [account]
  );
  const userBalanceF = useCallback(async () => {
    if (!account) return;
    const web3 = await getWeb3(chainId);
    let val = await web3.eth.getBalance(account);
    setUserBalance(Web3.utils.fromWei(val));
  }, [account, chainId]);

  const userWrappedBalance = useCallback(async () => {
    if (!account) return;
    if (!wrappedTokenContract) return;
    let val = await wrappedTokenContract.methods.balanceOf(account).call();
    setWrappedTokenBalance(Web3.utils.fromWei(val));
  }, [account, wrappedTokenContract]);

  useEffect(() => {
    userBalanceF();
  }, [account, userBalanceF, chainId]);

  useEffect(() => {
    userWrappedBalance();
  }, [account, userWrappedBalance, chainId]);

  useEffect(() => {
    const refresh = setInterval(async () => await userBalanceF(), REFRESH_TIME);
    return () => {
      clearInterval(refresh);
    };
  }, [userBalanceF]);

  useEffect(() => {
    const refresh = setInterval(
      async () => await userWrappedBalance(),
      REFRESH_TIME
    );
    return () => {
      clearInterval(refresh);
    };
  }, [userWrappedBalance]);

  useEffect(() => {
    requestSignatureLogin();
  }, [requestSignatureLogin]);
  return (
    <>
      <div className={themeChange.add("section-2")}>
        <CustomModal
          show={registerWalletModal}
          onHide={toggleRegisterWalletModal}
        >
          <div className={themeChange.add("section-2")}>
            <div className="checkout">
              <div className="maincheckout">
                <div className="row">
                  <div className="col">
                    <h3>Wallet address not registered!</h3>
                  </div>
                  <div className="col-1">
                    <button
                      className="btn-close btn-close-white"
                      onClick={toggleRegisterWalletModal}
                    />
                  </div>
                </div>
                <p style={{ marginBottom: "0px" }}>
                  Register your public wallet to your profile.
                </p>
                <div className="d-flex flex-row align-items-center mt-3 ml-3">
                  <input
                    type="submit"
                    id="submit"
                    className="btn-main"
                    onClick={handleRegisterUserWallet}
                    value="Confirm"
                  />
                  <input
                    type="submit"
                    id="submit"
                    className="btn-main-secondary"
                    style={{ marginLeft: "10px" }}
                    onClick={handleCancelRegister}
                    value="Cancel"
                  />
                </div>
              </div>
            </div>
          </div>
        </CustomModal>

        <WrappedModal
          size="lg"
          exchangeModel={exchangeModel}
          toggleExchangeModel={toggleExchangeModel}
        />
        <OffCanvasComponent
          background={`${theme === "dark" ? "#212428" : "#FFFFFF"}`}
          placement="end"
          {...props}
        >
          <div className={themeChange.add("section-2")}>
            {account && (
              <div className="row w-100-nav">
                <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                  <div className="d-flex justify-content-between align-items-center">
                    <img
                      src={
                        auth === "MetaMask"
                          ? "./img/wallet/1.png"
                          : auth === "WalletLink"
                          ? "./img/wallet/5.png"
                          : "./img/wallet/wallet.png"
                      }
                      alt=""
                      width={25}
                      style={{ marginRight: "10px" }}
                    />
                    <h4
                      className="mb-0"
                      style={{ color: theme === "dark" ? null : "#000000" }}
                    >
                      My wallet
                    </h4>
                  </div>
                  <button
                    className="btn-close btn-close-white"
                    onClick={props.handleClose}
                  />
                </div>
                <hr
                  className="mb-3"
                  style={{ borderColor: theme === "dark" ? null : "#000000" }}
                />
                <div className="col-12 col-lg-12 mb-3">
                  <div className="mb-3">
                    Connected Wallet:
                    {auth === "MetaMask" ? (
                      <span
                        className={`fw-bold ${
                          theme === "dark" ? "text-white" : "text-black"
                        }`}
                      >
                        {" "}
                        Metamask{" "}
                        <img
                          className="pb-1"
                          src="./img/wallet/1.png"
                          alt=""
                          width={20}
                        />
                      </span>
                    ) : auth === "WalletLink" ? (
                      <span
                        className={`fw-bold ${
                          theme === "dark" ? "text-white" : "text-black"
                        }`}
                      >
                        {" "}
                        Coinbase{" "}
                        <img
                          className="pb-1"
                          src="./img/wallet/5.png"
                          alt=""
                          width={20}
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    className="wallet-balance mb-4"
                    style={{
                      // color: theme === "dark" ? null : "#000000",
                      boxShadow:
                        theme === "dark"
                          ? null
                          : "1px 1px 8px 1px rgb(0 0 0 / 10%)",
                    }}
                  >
                    <p>Total Balance</p>
                    <h4
                      style={{
                        color: theme === "dark" ? null : "#000000",
                      }}
                    >
                      {Math.floor(userBalance * PRICE_CONSTANTS) /
                        PRICE_CONSTANTS}{" "}
                      <span style={{ marginRight: 5 }}>
                        <PolygonMatic
                          style={{ marginTop: -5 }}
                          src={
                            COMMON_BASE_TOKENS[chainId]?.imgSrc ||
                            COMMON_BASE_TOKENS[DEFAULT_CHAIN_ID]?.imgSrc
                          }
                        />
                      </span>
                    </h4>
                    {/* <Button className="btn-main inline lead">Add Funds</Button> */}
                  </div>
                  <div
                    className="wallet-balance mb-4"
                    style={{
                      // color: theme === "dark" ? null : "#000000",
                      boxShadow:
                        theme === "dark"
                          ? null
                          : "1px 1px 8px 1px rgb(0 0 0 / 10%)",
                    }}
                  >
                    <p>Available Wrapped Token</p>
                    <h4
                      style={{
                        color: theme === "dark" ? null : "#000000",
                      }}
                    >
                      {Math.floor(wrappedTokenBalance * PRICE_CONSTANTS) /
                        PRICE_CONSTANTS}{" "}
                      <span style={{ marginRight: 5 }}>
                        <PolygonMatic
                          style={{ marginTop: -5 }}
                          src={
                            COMMON_BASE_TOKENS[chainId]?.imgSrc ||
                            COMMON_BASE_TOKENS[DEFAULT_CHAIN_ID]?.imgSrc
                          }
                        />
                      </span>
                    </h4>
                    {/* <Button className="btn-main inline lead">Add Funds</Button> */}
                    <span
                      className="exchange_token"
                      onClick={toggleExchangeModel}
                    >
                      Exchange Token
                    </span>
                  </div>

                  <span className="box-url text-center">
                    <p className="mb-0">Wallet address</p>
                    <ClickToCopy text={account}>
                      <h5
                        style={{
                          marginBottom: "0px",
                          color: theme === "dark" ? null : "#000000",
                        }}
                        // onClick={handleCopy}
                      >
                        {walletAddress || ""}
                      </h5>
                    </ClickToCopy>
                    {/* <div id='wallet' style={{ display: "none" }}>
                  {account}
                </div> */}
                    {/* {actionCopy ? <p className='text-copied'>Copied</p> : ""} */}
                  </span>
                  <div className={themeChange.add("section-2")}>
                    <div className="mt-5 d-flex justify-content-center align-items-center">
                      <span
                        className={themeChange.add("btn-main")}
                        onClick={onDisconnectWallet}
                      >
                        Disconnect
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!account && (
              <div className="row w-100-nav">
                <h4
                  className="mt-3 mb-4"
                  style={{ color: theme === "dark" ? null : "#000000" }}
                >
                  Wallet Not Connected
                </h4>
                <hr
                  className="mb-3"
                  style={{ borderColor: theme === "dark" ? null : "#000000" }}
                />
                <div className="col-lg-12">
                  <p
                    className="mb-4"
                    style={{ color: theme === "dark" ? null : "#000000" }}
                  >
                    Please connect with following wallet.
                  </p>
                  <div className="d-flex gap-2 mb-4 align-items-center">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                      style={{ cursor: "pointer" }}
                    />
                    <span style={{ color: theme === "dark" ? "" : "#212428" }}>
                      I agree{" "}
                      <Link to="/terms" className="_terms">
                        Terms and Conditions
                      </Link>
                    </span>
                  </div>
                  <div className="col-lg-12 mb-4">
                    <span
                      className={`box-url d-flex justify-content-between align-items-center ${
                        !isChecked && "not_interactive"
                      }`}
                      onClick={handleConnectWallet}
                    >
                      {/* <span className="box-url-label">Most Popular</span> */}
                      <div className="d-flex justify-content-between align-items-center">
                        <img
                          src="./img/wallet/1.png"
                          alt=""
                          width={30}
                          style={{ marginRight: "10px" }}
                        />
                        <h5
                          style={{
                            marginBottom: "0px",
                            color: theme === "dark" ? null : "#000000",
                          }}
                        >
                          Metamask
                        </h5>
                      </div>
                    </span>
                  </div>
                  <div className="col-lg-12 mb-4 ">
                    <div className="col-lg-12 mb-4 ">
                      <span
                        className={`box-url d-flex justify-content-between align-items-center ${
                          !isChecked && "not_interactive"
                        }`}
                        onClick={handleCoinbaseConnect}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <img
                            src="./img/wallet/5.png"
                            alt=""
                            width={30}
                            style={{ marginRight: "10px" }}
                          />
                          <h5
                            style={{
                              marginBottom: "0px",
                              color: theme === "dark" ? null : "#000000",
                            }}
                          >
                            Coinbase
                          </h5>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <div>
        <ChangeChainButton />
      </div> */}
          </div>
        </OffCanvasComponent>
      </div>
    </>
  );
}
