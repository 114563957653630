import Web3 from "web3";

import { CHAIN_RPC_URL_CHAINID } from "constants/chain.constants";
import {
  DEFAULT_CHAIN_ID,
  SUPPORTED_CHAIN_ID,
} from "constants/global-constants";

export const getWeb3 = (chainId) => {
  let httpProvider;

  if (!chainId || !SUPPORTED_CHAIN_ID.includes(Number(chainId)))
    httpProvider = CHAIN_RPC_URL_CHAINID[DEFAULT_CHAIN_ID][0];
  else {
    httpProvider = CHAIN_RPC_URL_CHAINID[chainId][0];
  }

  if (!httpProvider) {
    httpProvider = CHAIN_RPC_URL_CHAINID[DEFAULT_CHAIN_ID][0];
  }

  httpProvider = new Web3.providers.HttpProvider(httpProvider, {
    timeout: 10000,
  });

  const web3 = new Web3(httpProvider);
  return web3;
};

export const getContract = (abi, address, contractOptions) => {
  const web3 = getWeb3();
  return new web3.eth.Contract(abi, address, contractOptions);
};

export const compareWalletAdress = async (address1, address2) => {
  if (!address1 || !address2) return false;
  const addr1 = await Web3.utils.toChecksumAddress(address1);
  const addr2 = await Web3.utils.toChecksumAddress(address2);
  return addr1 === addr2;
};

export const isValidAddress = (address) => {
  if (!address) return false;

  return Web3.utils.isAddress(address);
};
