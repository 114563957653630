import ErrorBoundary from "components/error-boundary/ErrorBoundary";
import React from "react";
import Loader from "../Spinner";
import { useThemeChange } from "utils/themeChange";

const Loading = ({ children, loading = true, titleHeader = "" }) => {
  const themeChange = useThemeChange();
  if (loading) {
    return (
      <div className={themeChange.add("section")}>
        <ErrorBoundary>
          <div
            style={{
              position: "absolute",
              top: "40%",
              bottom: "50%",
              textAlign: "center",
              left: "45%",
              right: "45%",
            }}>
            <Loader />
            <h5>Please wait... Loading</h5>
          </div>
        </ErrorBoundary>
      </div>

    );
  }
  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export default Loading;
