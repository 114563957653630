import React from "react";
import { DEFAULT_NET } from "constants/global-constants";
import {
  CHAIN_IDS,
  CHAIN_RPC_URLS,
  CHAIN_EXPLORERS,
  COMMON_BASE_TOKENS,
} from "constants/chain.constants";
import { useWeb3React } from "@web3-react/core";

function ErrorMessage() {
  const {library} = useWeb3React();

  const getNetworkConnectParams = () => ({
    [DEFAULT_NET]: [
      {
        chainId: CHAIN_IDS.networkIdHex[DEFAULT_NET],
        chainName: DEFAULT_NET,
        nativeCurrency: {
          name: COMMON_BASE_TOKENS[CHAIN_IDS.networkId[DEFAULT_NET]].name,
          symbol: COMMON_BASE_TOKENS[CHAIN_IDS.networkId[DEFAULT_NET]].symbol,
          decimals:
            COMMON_BASE_TOKENS[CHAIN_IDS.networkId[DEFAULT_NET]].decimals,
        },
        rpcUrls: [...CHAIN_RPC_URLS[DEFAULT_NET]],
        // blockExplorerUrls: [
        //   ...CHAIN_EXPLORERS[CHAIN_IDS.networkIdHex[DEFAULT_NET]],
        // ],
      },
    ],
  });


  const chainId=CHAIN_IDS.networkIdHex[DEFAULT_NET]

  const handleChangeNetwork = async (e) => {
    e.preventDefault();
    try{
      await library.currentProvider.request({
      method: "wallet_switchEthereumChain",
      params: [{chainId}],
    });
  }
  catch(err){
    console.log(err)
      if(err.code === 4902){
        try{
          await library.currentProvider.request({
          method: "wallet_addEthereumChain",
          params: getNetworkConnectParams()[DEFAULT_NET],
        });
      }
      catch(err){
        
      }

      }

    }
  };

  return (
    <div className="error-message-container">
      <p className="error-message">
        You're viewing data from the {DEFAULT_NET}.
        <a
          className="error-message"
          href="/"
          style={{ color: "rgb(24, 104, 183)" }}
          onClick={(e) => handleChangeNetwork(e)}
        >
          {" "}
          Connect to {DEFAULT_NET}
        </a>
      </p>
      {/* <AiOutlineClose
            size={20}
            className="close-button"
            onClick={handleClose}
          /> */}
    </div>
  );
}

export default ErrorMessage;
