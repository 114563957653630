import React, { useState, useEffect } from "react";
import { useThemeChange } from "utils/themeChange";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const themeChange = useThemeChange();

  const toggleVisibility = () => {
    if (window.pageYOffset > 600) setIsVisible(true);
    else setIsVisible(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      toggleVisibility();
    });
  }, []);

  return (
    <div className={themeChange.add("section-2")}>
      <div id="scroll-to-top" className="init">
        {isVisible && (
          <div onClick={() => scrollToTop()}>
            <i className=""></i>
          </div>
        )}
      </div>
    </div>

  );
};

export default ScrollToTop;