import { useCallback, useEffect, useRef } from "react";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import { injected, walletlink } from "./connectors";

export const useAuth = () => {
  const { activate, deactivate, account, chainId } = useWeb3React();
  const mounted = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!mounted.current) mounted.current = true;
    else if (!account) {
      localStorage.removeItem("-walletlink:https://www.walletlink.org:Addresses");
      localStorage.removeItem("-walletlink:https://www.walletlink.org:walletUsername");
      // localStorage.removeItem("auth");
      deactivate();

    }
  }, [account, navigate, chainId,deactivate]);

  const connectMetaMask = useCallback(async (providerName) => {
    try {
      const{ethereum} = window;
        if(!ethereum?.providers){
        
          if(ethereum.isMetaMask) 
{           await activate(injected);
            localStorage.setItem("auth", "MetaMask");

}          else return undefined;
        }

       else { let provider = window.ethereum;
        switch (providerName) {
          case 'CoinBase':
              provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
              break;
          case 'MetaMask':
              provider = ethereum.providers.find(({isMetaMask,isBraveWallet}) => isMetaMask && !isBraveWallet);
              break;
          default:
            provider = ethereum.providers.find(({isMetaMask,isBraveWallet}) => isMetaMask && !isBraveWallet);
      }

      if (provider) {
        ethereum.setSelectedProvider(provider);
    }
    await activate(injected);

      localStorage.setItem("auth", "MetaMask");}
    } catch (e) {
      console.log(e);
      localStorage.removeItem("auth");
    }
  }, [activate]);

  const connectCoinbase = useCallback(async () => {
    try {
      await activate(walletlink);
      localStorage.setItem("auth", "WalletLink");
    } catch (e) {
      console.log(e);
      localStorage.removeItem("auth");
      localStorage.removeItem("-walletlink:https://www.walletlink.org:Addresses")
    }
  }, [activate]);

  const logOut = useCallback(() => {
    deactivate();
    localStorage.removeItem("auth");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("auth_signature");
  }, [deactivate]);

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("auth") === "MetaMask") {
        await connectMetaMask();
      }
      if (localStorage.getItem("auth") === "WalletLink") {
        await connectCoinbase();
      }
    })();
  }, [connectMetaMask, connectCoinbase]);

  return { connectMetaMask, connectCoinbase, logOut };
};
